import type { ReactElement } from 'react'
import { createContext } from 'react'

export type Message = string | ReactElement

export type Variant = 'success' | 'warning' | 'error' | 'info'

export type ShowToastArgs = {
  message: Message
  variant: Variant
}

/**
 * Context
 */

type ContextValue = {
  showToast: (args: ShowToastArgs) => void
}

export const Context = createContext<ContextValue | undefined>(undefined)
