import { isNil } from 'ramda'

import type { I18nLanguage } from './i18n-provider'

type ShimConfig = {
  language?: I18nLanguage
}

export const initShims = async ({ language }: ShimConfig) => {
  if (isNil(global.Intl)) {
    if (language === undefined) {
      throw new Error('Shim: Missing language value')
    }

    await import(/* webpackChunkName: "intl/intl" */ 'intl')
    await import(
      /* webpackInclude: /(en|pl|cs)\.js$/ */
      /* webpackChunkName: "intl/intl_locale" */
      `intl/locale-data/jsonp/${language}.js`
    )
  }

  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  // this is a workaround for Cypress to be able to wait/stub network requests - see https://github.com/cypress-io/cypress/issues/95
  if (typeof window.fetch === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await import('whatwg-fetch')
  }
}
