import { duration, easing } from '@twisto/styles'

export const thmemeChangeAnimation = (transitionReady: Promise<unknown>) => {
  transitionReady.then(() => {
    document.documentElement.animate(
      {
        clipPath: ['circle(0%)', 'circle(100%)'],
      },
      {
        duration: duration.complex,
        easing: easing.easeIn,
        fill: 'forwards',
        pseudoElement: '::view-transition-new(root)',
      }
    )
  })
}
