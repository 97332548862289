import type {
  ActionPaletteColor,
  AdditionalPaletteColor,
  BackgroundPaletteColor,
  BrandPaletteColor,
  NeutralPaletteColor,
  NewThemePalette,
  OtherPaletteColor,
  StatePaletteColor,
} from './themes'

export const neutral = {
  100: '#5c5c5c29', // with opacity
  200: '#5c5c5c3d', // with opacity
  300: '#5c5c5c5c', // with opacity
  600: '#5C5C5C',
  700: '#7B7B7B',
  800: '#9A9A9A',
  900: '#E6E6E6',
} as const satisfies NeutralPaletteColor

export const brand = {
  100: '#3BBAB229', // with opacity
  200: '#3BBAB252', // with opacity
  400: '#359B95',
  500: '#3BBAB2',
} as const satisfies BrandPaletteColor

export const action = {
  '000': '#FFFFFF',
  400: '#359B95',
  500: '#3BBAB2',
  // TODO: remove gradient
  gradient: 'linear-gradient(45deg, #3BBAB2 0%, #3BBAB2 100%)',
} as const satisfies ActionPaletteColor

export const background = {
  primary: '#131315',
  secondary: '#1D1D20',
} as const satisfies BackgroundPaletteColor

export const other = {
  info500: '#795CD3',
  white: '#FFFFFA',
  overlay: '#3a3a40a3', // with opacity
  overlayDarker: '#000000d6', // with opacity
  overlayWhite12: '#ffffff1f', // with opacity
  overlayTextBg: '#ffffffb8', // with opacity
  overlayNeutral: '#000000B8', // with opacity
} as const satisfies OtherPaletteColor

export const success = {
  100: '#47d76429', // with opacity
  200: '#47d76452', // with opacity
  500: '#47D764',
} as const satisfies StatePaletteColor

export const warning = {
  100: '#fba94b29', // with opacity
  200: '#fba94b52', // with opacity
  500: '#FBA94B',
} as const satisfies StatePaletteColor

export const error = {
  100: '#ed5a5a29', // with opacity
  200: '#ed5a5a52', // with opacity
  500: '#ED5A5A',
} as const satisfies StatePaletteColor

export const additionalColor = {
  purple: {
    100: '#393056',
    200: '#4B3D7A',
    500: '#AB9AE1',
    700: '#420A87', // used on homepage
  },
  section: '#E9E6EF', // used on homepage
  tableBackground: '#F6F7F8',
} as const satisfies AdditionalPaletteColor

export const palette = {
  neutral,
  brand,
  action,
  background,
  other,
  success,
  warning,
  error,
  additionalColor,
} as const satisfies NewThemePalette
