import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const wrapper = css`
  display: block;
  position: relative;
  transform-origin: center;
  transform-style: preserve-3d;
  background: none;

  /* vertically center modal */
  margin: 0 auto;
`

export const disabledScroll = css`
  max-height: 100%;
  overflow-y: hidden;
`

export const verticalAlign = {
  center: css`
    align-self: center;
  `,
  top: css`
    align-self: start;
    margin: ${styles.size[48]} auto;

    ${styles.breakpoint.up('sm')} {
      margin: ${styles.size[96]} auto;
    }
  `,
}

export const variants = {
  default: css`
    width: 100%;
    max-width: 580px;
  `,
  centered: css`
    width: 100%;
    max-width: 580px;
    text-align: center;
  `,
  wide: css`
    width: 100%;
    height: 100%;
    margin: ${styles.size[0]} auto;
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;

    ${styles.breakpoint.up('sm')} {
      position: relative;
      width: 87.7%;
      max-height: 1080px;
      max-width: ${styles.width.modal.maxWidth};
    }
  `,
}

export const autoWidth = css`
  width: auto;
  max-width: 100%;
`

export const content = (theme: Theme) => css`
  position: relative;
  white-space: normal;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  padding: ${styles.size[24]} ${styles.size[16]};
  border-radius: ${styles.borderRadius.sm} ${styles.borderRadius.lg};

  background: ${theme.palette.background.primary};

  ${styles.breakpoint.up('sm')} {
    padding: ${styles.size[32]} ${styles.size[24]};
    margin: 0;
  }

  ${styles.breakpoint.up('md')} {
    padding: 45px;
    margin: 0;
    border-radius: ${styles.borderRadius.md} ${styles.borderRadius.xl};
  }
`

export const wideContent = css`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

export const noPadding = css`
  padding: 0;

  ${styles.breakpoint.up('sm')} {
    padding: 0;
  }
`
