import type { HTMLAttributes, ReactNode } from 'react'

import { DialogOverlay } from '@reach/dialog'
import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'

import { animationConfig } from '../../utils/animation-config'

import { ModalLock } from './modal-lock'
import * as styles from './modal-overlay.styles'

const AnimatedDialogOverlay = animated(DialogOverlay)

type Props = {
  children: ReactNode
  isVisible: boolean
  hasAnimation?: boolean
  hasOverlay: boolean
  dangerouslyBypassFocusLock?: boolean
  centered?: boolean
  onClickOutside: (() => void) | undefined
  className?: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'style'>

export const ModalOverlay = ({
  children,
  isVisible,
  hasAnimation = true,
  hasOverlay,
  dangerouslyBypassFocusLock,
  onClickOutside,
  centered = false,
  className,
  ...rest
}: Props) => {
  const opacityValue = hasAnimation ? 0 : 1

  const transition = useTransition(isVisible, {
    from: { opacity: opacityValue },
    enter: () => async (next) => {
      await next({ pointerEvents: 'auto' })
      await next({ opacity: 1 })
    },
    leave: () => async (next) => {
      await next({ pointerEvents: 'none' })
      await next({ opacity: opacityValue })
    },
    config: animationConfig,
  })

  return (
    <ModalLock isVisible={isVisible}>
      {transition(
        (transitionStyles, item) =>
          item && (
            <AnimatedDialogOverlay
              dangerouslyBypassScrollLock
              className={className}
              css={[
                styles.container,
                hasOverlay && styles.overlay,
                centered && styles.centered,
              ]}
              dangerouslyBypassFocusLock={dangerouslyBypassFocusLock}
              data-testid="modal-overlay"
              style={transitionStyles}
              {...rest}
              onDismiss={onClickOutside}
            >
              {children}
            </AnimatedDialogOverlay>
          )
      )}
    </ModalLock>
  )
}
