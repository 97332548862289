import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { breakpoint, htmlFontSize, pxToRem } from '@twisto/styles'

import { ParamHeavy, ParamMedium, ParamRegular } from './fonts'

export const paramFont = (fontScaling: boolean) => css`
  @font-face {
    font-family: 'Param';
    font-weight: 400;
    font-display: swap;
    src: url('${ParamRegular}') format('woff2');
  }
  @font-face {
    font-family: 'Param';
    font-weight: 500;
    font-display: swap;
    src: url('${ParamMedium}') format('woff2');
  }
  @font-face {
    font-family: 'Param';
    font-weight: 700;
    font-display: swap;
    src: url('${ParamHeavy}') format('woff2');
  }

  * {
    font-family: 'Param', Helvetica, Arial, sans-serif;
  }

  html {
    font-size: ${fontScaling
      ? pxToRem(htmlFontSize.md)
      : `${htmlFontSize.md}px`};

    ${breakpoint.up('md')} {
      font-size: ${fontScaling
        ? pxToRem(htmlFontSize.lg)
        : `${htmlFontSize.lg}px`};
    }
  }
`

type GlobalStylesOptions = {
  noBackground?: boolean
}
export const global = (
  theme: Theme,
  { noBackground = false }: GlobalStylesOptions = {}
) => css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    outline: none;
  } /* Switch to border-box for box-sizing. */

  /* With direction support we need to specify how bidirectional text in a document is handled. */
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/unicode-bidi */
  * {
    unicode-bidi: embed;
  }

  /* Immediately jump any animation to the end point if the user has set their device to "prefers reduced motion". */
  /* This could create bad, unintended consequences. Remove as needed, and write your own appropriate code for prefers-reduced-motion. */

  @media (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001s !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001s !important;
    }
  }

  :root {
    /* Enable skipping import of Reach-dialog styles which we don't use  */
    /* More in https://reach.tech/styling/ */
    --reach-dialog: 1;
  }

  /* Set the default color scheme to be light for native elements */
  [data-theme='dark'] {
    color-scheme: dark;
  }

  /* Set the default color scheme to be dark for native elements */
  [data-theme='light'] {
    color-scheme: light;
  }

  html {
    /* Fix for jumping scrollbar */
    /* https://aykevl.nl/2014/09/fix-jumping-scrollbar */
    width: 100vw;
    overflow-x: hidden;
  }

  body {
    margin: 0; /* Remove the tiny space around the edge of the page */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.palette.neutral[900]};
    background: ${noBackground ? 'initial' : theme.palette.background.primary};
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    overflow-y: auto;

    @supports (height: 100svh) {
      min-height: 100svh;
    }
  }

  /* Improve readability */
  p,
  ul,
  ol,
  dl,
  address {
    line-height: 1.5;
  }

  pre {
    white-space: pre-wrap; /* Overflow by default is bad. */
  }

  hr {
    border: 0.5px solid;
  }

  /* Remove default input autocomplete styles */
  textarea:-webkit-autofill,
  input:-webkit-autofill,
  select:-webkit-autofill {
    /* Only viable solution to make the background transparent */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: ${theme.palette.neutral[900]};
    color: ${theme.palette.neutral[900]};
  }

  /* Are browsers now consistent with margin & padding on lists?
  See: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Lists_and_Counters/Consistent_list_indentation  */
  nav ul {
    list-style: none;
  }

  img,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block; /* Switch display mode to block, since that's what we usually want for images. */
    vertical-align: middle; /* If you override, and make an image inline, it's likely you'll want middle vertical alignment. */
  }
  img,
  video {
    max-width: 100%; /* Make images and video flexible by default. */
    height: auto; /* Ensure images and video maintain their aspect ratio when max-width comes into play. */
  }
  img {
    border-style: none; /* Remove the border on images inside links in IE 10 and earlier. */
  }

  b,
  strong {
    font-weight: 500; /* We are using 500 for 'bold' */
  }

  /* In English, when styling the <q> element, use curly quotes instead of straight quotes. */

  /* Code for this is now in the quotes.css file */

  /* Support upcoming properties that haven't been broadly implemented yet,
  but for which the initial value and legacy behavior is not be the best behavior.
  */

  :root {
    /* Consistent line spacing, which does not unnecessarily grow to accommodate things that would fit anyway */
    /* From CSS Inline Layout Module Level 3: https://drafts.csswg.org/css-inline-3/#line-sizing-property */
    line-sizing: normal;

    /* Improve spacing of punctuation marks and at script changes in CJK languages */
    /* From CSS Text Module Level 4: https://drafts.csswg.org/css-text-4/#text-spacing-property */
    text-spacing: trim-start allow-end trim-adjacent ideograph-alpha
      ideograph-numeric;
  }

  ::view-transition-old(root),
  ::view-transition-new(root) {
    animation: none;
  }
`
