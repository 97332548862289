import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { pxToRem } from '@twisto/styles'

export const root = css`
  margin: 0;
  display: block;
`

export const typographyVariant = {
  h1: css`
    font-size: ${pxToRem(32)};
    line-height: ${pxToRem(44)};
    letter-spacing: -1px;
  `,
  h2: css`
    font-size: ${pxToRem(26)};
    line-height: ${pxToRem(36)};
    letter-spacing: -1px;
  `,
  h3: css`
    font-size: ${pxToRem(21)};
    line-height: ${pxToRem(31)};
    letter-spacing: -0.6px;
  `,
  b1: css`
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(26)};
    letter-spacing: -0.4px;
  `,
  b2: css`
    font-size: ${pxToRem(17)};
    line-height: ${pxToRem(24)};
    letter-spacing: -0.4px;
  `,
  b3: css`
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(22)};
    letter-spacing: -0.3px;
  `,
  b4: css`
    font-size: ${pxToRem(13)};
    line-height: ${pxToRem(18)};
    letter-spacing: -0.3px;
  `,
  b5: css`
    font-size: ${pxToRem(11)};
    line-height: ${pxToRem(16)};
    letter-spacing: -0.3px;
  `,
}

export const fontWeight = {
  regular: css`
    font-weight: 400;
  `,
  medium: css`
    font-weight: 500;
  `,
  heavy: css`
    font-weight: 700;
  `,
}

export const color = (theme: Theme) => ({
  textPrimary: css`
    color: ${theme.palette.neutral[900]};
  `,
  textSecondary: css`
    color: ${theme.palette.neutral[800]};
  `,
  textTertiary: css`
    color: ${theme.palette.neutral[700]};
  `,
  primary: css`
    color: ${theme.palette.brand[500]};
  `,
  success: css`
    color: ${theme.palette.success[500]};
  `,
  secondary: css`
    color: ${theme.palette.additionalColor.purple[500]};
  `,
  warning: css`
    color: ${theme.palette.warning[500]};
  `,
  error: css`
    color: ${theme.palette.error[500]};
  `,
})

export const align = (align: 'start' | 'center' | 'end') => {
  if (align === 'start') {
    return css`
      text-align: left;
    `
  }

  if (align === 'end') {
    return css`
      text-align: right;
    `
  }

  return css`
    text-align: center;
  `
}

export const textTransform = {
  none: css`
    text-transform: none;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  lowercase: css`
    text-transform: lowercase;
  `,
  capitalize: css`
    text-transform: capitalize;
  `,
  lineThrough: css`
    text-decoration: line-through;
  `,
}

export const inline = css`
  display: inline;
`

export const noWrap = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const gutterBottom = css`
  margin-bottom: 0.3em;
`
