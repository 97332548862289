import { isDev, settings } from '@twisto/environment'
import { format } from '@twisto/form'

import { envConfig } from '../env-config'

import type { CustomerConfig, RegionConfig } from './types'

const { BASE_DOMAIN, STATIC_DOMAIN, HOST } = envConfig
const { DAKTELA_GUI_TOKEN_CZ } = settings

export const czDocs = {
  accountTerms: `${STATIC_DOMAIN}/base/docs/formular_odlozeni_ucet.pdf`,
  businessCommunicationTerms: `${BASE_DOMAIN}/podminky/zasilani-obchodnich-sdeleni/`,
  cardAgreement: `${BASE_DOMAIN}/podminky/karta/`,
  cnbLicence: `${BASE_DOMAIN}/podminky/registrace-cnb/`,
  exchangeRate: `${BASE_DOMAIN}/kurzovni-listek/`,
  insuranceConditions: `${STATIC_DOMAIN}/base/docs/pojisteni-podminky.pdf`,
  insuranceContract: `${STATIC_DOMAIN}/base/docs/pojisteni-smlouva.pdf`,
  insuranceDeclaration: `${STATIC_DOMAIN}/base/docs/pojisteni-prohlaseni.pdf`,
  insuranceInfo: `${BASE_DOMAIN}/podminky/zprostredkovani-pojisteni/`,
  insuranceList: `${STATIC_DOMAIN}/base/docs/pojisteni-informacni-list.pdf`,
  personalDataPolicy: `${BASE_DOMAIN}/podminky/zpracovavani-udaju/`,
  telcoAgreement: `${STATIC_DOMAIN}/base/docs/telco_score_souhlas.pdf`,
  termsOfService: `${BASE_DOMAIN}/podminky/`,
}

export const czUrls = {
  signup: `${BASE_DOMAIN}/start/onboarding/`,
  verification: `${BASE_DOMAIN}/start/onboarding/`,
  documents: `${BASE_DOMAIN}/zakaznik/dokumenty/`,
  twistoPayShops: `${BASE_DOMAIN}/seznam-eshopu/`,
  homepage: `${BASE_DOMAIN}/`,
  snap: `${BASE_DOMAIN}/zakaznik/snap/`,
  eshopLogin: `https://eshop.${HOST.replace('www.', '')}/login/`,
  twistoFeatures: 'https://www.twisto.cz/vychytavky/',
  billing: `${BASE_DOMAIN}/app/billing/`,
  overview: `${BASE_DOMAIN}/app/overview/`,
  twistoSupport: 'https://twisto.cz/category/pro-uzivatele',
}

export const czCustomerConfig: CustomerConfig = {
  personalId: {
    placeholder: true,
    maxLength: 11,
  },
  externalTwistoExchangeRateUrl: 'https://www.twisto.cz/kurzovni-listek/',
  externalTwistoPayUrl: 'https://www.twisto.cz/seznam-eshopu/',
  twistoSupportUrl: czUrls.twistoSupport,
  installmentsTwistoSupportUrl:
    'https://twisto.cz/category/pro-uzivatele/twisto-ucet/splatky/',
  twistoSnapEmail: 'plati@twisto.cz',
  twistoSnapSupportUrl:
    'https://twisto.cz/article/twisto-ucet/twisto-snap/co-je-to-twisto-snap/',
  trialMonthsPeriod: 3,
  maxPlanChargeFee: 300,
}

const config: RegionConfig & typeof envConfig = {
  ...envConfig,
  defaultRegionLanguage: 'cs',
  addressVariant: 'address',
  callingCodes: ['+420', '+421'],
  currencyCode: 'CZK',
  currencySign: 'Kč',
  featureFlags: {
    donations: true,
    twistoPay: true,
    applePay: true,
    pspGateway: false,
    payInThreeDetail: true,
    mobileAppAvailable: true,
    fontScaling: isDev,
  },
  formattedOptions: {
    presets: {
      zipCode: {
        blocks: [format.blocks.three, format.blocks.two],
        delimiter: format.delimiters.space,
        numericOnly: true,
      },
      personalId: {
        blocks: [format.blocks.six, format.blocks.four],
        delimiter: format.delimiters.slash,
        numericOnly: true,
      },

      date: format.datePresets,
      money: format.moneyPresets,
    },
    currency: format.currencyPresets,
  },
  brand: 'twisto',
  support: {
    phoneNumber: '+420 222 70 33 33',
    email: 'dotaz@twisto.cz',
    hoursFrom: 8,
    hoursTo: 18,
    days: 'daily',
    daktelaGuiToken: DAKTELA_GUI_TOKEN_CZ,
  },
}

export default config
