import type { ReactNode } from 'react'

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { useAtom } from 'jotai'

import { useMatchMediaQuery } from '@twisto/hooks'
import { getTheme } from '@twisto/theme'
import type { ThemeModes, themes } from '@twisto/theme'
import { atomWithLocalStorage } from '@twisto/utils'

import { thmemeChangeAnimation } from './theme-change-animation'

type Props = {
  brand?: keyof typeof themes
  children: ReactNode
}

export type ThemeModesWithAuto = ThemeModes | 'auto'

const defaultValue = 'auto'

export const themeModeAtom = atomWithLocalStorage('themeMode', defaultValue)

export const ThemeProvider = ({ brand = 'param', children }: Props) => {
  const [themeMode] = useAtom(themeModeAtom)

  const isDarkModePreferred = useMatchMediaQuery(
    '(prefers-color-scheme: dark)',
    thmemeChangeAnimation
  )
  const detectedThemeMode =
    themeMode === 'auto'
      ? isDarkModePreferred
        ? 'dark'
        : 'light'
      : (themeMode as ThemeModes)

  if (typeof window !== 'undefined') {
    window.document.documentElement.setAttribute(
      'data-theme',
      detectedThemeMode
    )
  }

  const theme = getTheme(brand, detectedThemeMode)

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
}
