import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = css`
  display: flex;
  max-width: 100%;
`

export const variants = (theme: Theme) => ({
  main: css`
    flex: 2;
    width: 100%;
    position: relative;
    background: ${theme.palette.background.primary};
  `,
  screen: css`
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: relative;
    background: ${theme.palette.background.primary};

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  `,
  content: css`
    flex: 1;
    padding: ${styles.size[16]};

    position: relative;

    ${styles.breakpoint.up('sm')} {
      padding: ${styles.size[32]};
    }
  `,
})

export const grayBackground = (theme: Theme) => css`
  background: ${theme.palette.background.secondary};

  ${styles.breakpoint.down('xs')} {
    background: inherit;
  }
`

export const column = css`
  justify-content: center;
  flex-direction: column;
`

export const justify = {
  center: css`
    justify-content: center;
  `,
  flexStart: css`
    justify-content: flex-start;
  `,
  flexEnd: css`
    justify-content: flex-end;
  `,
  default: null,
}
export const screenHeightWithAppBar = css`
  min-height: calc(100vh - ${styles.appBar.minHeight});

  @supports (min-height: 100dvh) {
    min-height: calc(100dvh - ${styles.appBar.minHeight});
  }
`

export const withinCustomerLayout = css`
  min-height: calc(100vh - ${styles.appBar.minHeight});

  @supports (min-height: 100dvh) {
    min-height: calc(100dvh - ${styles.appBar.minHeight});
  }

  ${styles.breakpoint.up('sm')} {
    min-height: calc(100vh - ${styles.appBar.minHeight} - ${styles.size[32]});

    @supports (min-height: 100dvh) {
      min-height: calc(
        100dvh - ${styles.appBar.minHeight} - ${styles.size[32]}
      );
    }
  }
`
export const smallerPadding = css`
  padding: ${styles.size[16]};

  ${styles.breakpoint.up('sm')} {
    padding: ${styles.size[32]} ${styles.size[16]};
  }
`

export const fixedAppBarMargin = css`
  margin-top: ${styles.appBar.minHeight};
`

export const drawer = css`
  flex: 2;

  transition: margin ${styles.transitions.default};

  max-width: ${styles.drawer.expandedWidth.sm}px;

  ${styles.breakpoint.up('sm')} {
    max-width: ${styles.drawer.expandedWidth.lg}px;
  }
`

export const drawerHeader = css`
  min-height: ${styles.appBar.minHeight};
`

export const drawerVariants = {
  persistent: css`
    margin-right: -${styles.drawer.expandedWidth.sm}px;

    ${styles.breakpoint.up('sm')} {
      margin-right: -${styles.drawer.expandedWidth.lg}px;
    }
  `,
  permanent: css`
    margin-right: -${styles.drawer.expandedWidth.sm - styles.drawer.collapsedWidth.sm}px;

    ${styles.breakpoint.up('sm')} {
      margin-right: -${styles.drawer.expandedWidth.lg - styles.drawer.collapsedWidth.lg}px;
    }
  `,
}

export const borders = {
  right: (theme: Theme) => css`
    border-right: 1px solid ${theme.palette.neutral[200]};
  `,
  left: (theme: Theme) => css`
    border-left: 1px solid ${theme.palette.neutral[200]};
  `,
  none: css`
    border: none;
  `,
}

export const align = {
  flexStart: css`
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
  flexEnd: css`
    align-items: flex-end;
  `,
  default: null,
}
