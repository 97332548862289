import { useAtom } from 'jotai'
import { useRouter } from 'next/router'

import { deepEqualAtom } from '@twisto/utils'

export const locationStateAtom = deepEqualAtom<unknown>(undefined)

export const useLocation = <S = unknown>(): {
  search: string
  pathname: string
  href: string
  origin: string
  state?: S
} => {
  const router = useRouter()
  const [locationState] = useAtom(locationStateAtom)

  let asPath: string

  // for initial render in browser the nextjs router is not initialized yet so it's better to use window.location
  if (router.isReady || !process.browser) {
    asPath = router.asPath
  } else {
    const pathname = window.location.pathname.replace(
      new RegExp(`^${window.BASEPATH}`),
      ''
    )
    asPath = pathname + window.location.search
  }
  const currentUrl = new URL(asPath, window.location.origin)

  return {
    pathname: currentUrl.pathname,
    search: currentUrl.search,
    href: currentUrl.href,
    origin: currentUrl.origin,
    state: locationState as S,
  }
}
