import type { ComponentProps, ReactNode } from 'react'
import { Component, createContext, useContext } from 'react'

import { captureException } from '@sentry/react'
import { isNil } from 'ramda'

import { Loader } from '@twisto/components/atoms/loader'
import { isDev } from '@twisto/environment'
import { debug } from '@twisto/utils'

import type { AppErrorProps } from './app-error'
import { AppError } from './app-error'
import { ErrorModal } from './error-modal'
import { HandledError } from './handled-error'

// https://reactjs.org/docs/error-boundaries.html

const logError = (error: Error) =>
  isDev
    ? // eslint-disable-next-line no-console
      console.warn(`
        GLOBAL ERROR!:
        ==============
        ${debug(error)}
        ==============\n
      `)
    : captureException(error)

const ErrorBoundaryContext = createContext<
  AppErrorProps & {
    opened: boolean
    showLoader?: boolean
    setError: (error: AppError) => void
  }
>({ opened: false, setError: logError })

export const useErrorBoundaryContext = () => {
  const context = useContext(ErrorBoundaryContext)

  if (isNil(context)) {
    throw new Error(
      'useErrorBoundaryContext must be used within a ErrorBoundary'
    )
  }

  return context
}

type Props = {
  children:
    | ((props: { handleError: (error: Error) => void }) => JSX.Element)
    | ReactNode
}

export class ErrorBoundary extends Component<
  Props,
  ComponentProps<typeof ErrorBoundaryContext.Provider>['value']
> {
  state: ComponentProps<typeof ErrorBoundaryContext.Provider>['value'] = {
    opened: false,
    setError: (error) => {
      logError(error)
      this.setState({ ...error.modalErrorProps, opened: true })
    },
  }

  static getDerivedStateFromError(
    error: Error
  ): Partial<ComponentProps<typeof ErrorBoundaryContext.Provider>['value']> {
    if (error instanceof HandledError) {
      return { opened: true, showLoader: true }
    }

    logError(error)

    if (error instanceof AppError) {
      return { ...error.modalErrorProps, opened: true, withoutAnimation: true }
    }

    return { opened: true, errorText: error.message, withoutAnimation: true }
  }

  render() {
    return (
      <ErrorBoundaryContext.Provider value={this.state}>
        <>
          {!this.state.opened && this.props.children}
          {this.state.showLoader ?? false ? (
            <Loader />
          ) : (
            <ErrorModal {...this.state} />
          )}
        </>
      </ErrorBoundaryContext.Provider>
    )
  }
}
