import type { SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

import type { Size } from './headline'

export const size: Record<Size, SerializedStyles> = {
  default: css`
    width: ${styles.size[80]};
    height: ${styles.size[80]};
    margin-bottom: ${styles.size[24]};

    ${styles.breakpoint.down('sm')} {
      width: ${styles.size[64]};
      height: ${styles.size[64]};
      margin-bottom: ${styles.size[16]};
    }
  `,
  large: css`
    width: ${styles.size[96]};
    height: ${styles.size[96]};
    margin-bottom: ${styles.size[48]};

    ${styles.breakpoint.down('sm')} {
      width: ${styles.size[80]};
      height: ${styles.size[80]};
      margin-bottom: ${styles.size[32]};
    }
  `,
}
