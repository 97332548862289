import { useTheme } from '@emotion/react'

import { SvgIcon } from '../icons'

import type { LogoProps } from './logo'
import * as styles from './logo.styles'

export const TwistoBusinessLogo = ({
  variant = 'icon',
  inverse = false,
  className,
  size = 'medium',
}: LogoProps) => {
  const theme = useTheme()
  const viewBox = variant === 'icon' ? '0 0 32 32' : '0 0 124 32'
  const color = inverse ? theme.palette.other.white : styles.twistoLogoColor

  return (
    <SvgIcon
      className={className}
      css={[styles.root, styles.sizes[size]]}
      viewBox={viewBox}
    >
      <defs>
        <linearGradient
          id="green-gradient"
          x1="16.6509566%"
          x2="114.65%"
          y1="-12.6490434%"
          y2="85.35%"
        >
          <stop offset="0%" stopColor="#82BC00" stopOpacity="0" />
          <stop offset="100%" stopColor="#82BC00" />
        </linearGradient>
      </defs>

      {variant === 'full' ? (
        <>
          <g fill={color}>
            <path d="M48.6564 11.9625V7.07783H51.0331V4.58564H48.6564V1.54517H45.6491V4.58564H44V7.07783H45.6491V12.3613C45.6491 14.8535 47.0558 15.95 49.2384 15.95C50.063 15.95 50.7906 15.8005 51.1786 15.6011V12.8597C50.8391 13.1089 50.3055 13.2585 49.869 13.2585C49.1414 13.2086 48.6564 12.8099 48.6564 11.9625Z" />
            <path d="M55.1552 4.58569H52.1479L55.4462 15.7009H58.211L60.0056 8.87226L61.8003 15.7009H64.565L67.8633 4.58569H64.9531L63.0129 11.6635L61.1697 4.58569H58.89L57.0954 11.6635L55.1552 4.58569Z" />
            <path d="M69.4648 4.58569H72.4721V15.7507H69.4648V4.58569Z" />
            <path d="M80.7658 9.12123L78.5346 8.57295C77.8556 8.37357 77.5646 8.12435 77.5646 7.67576C77.5646 6.97794 78.2921 6.62904 79.0197 6.62904C80.0383 6.62904 80.5233 7.12748 80.7173 7.7256H83.5791C83.191 5.63216 81.6874 4.28638 78.9712 4.28638C76.546 4.28638 74.6543 5.73185 74.6543 7.92498C74.6543 9.81904 75.8184 10.6664 77.4675 11.0651L79.6502 11.5636C80.4263 11.763 80.7658 12.0122 80.7658 12.5106C80.7658 13.2583 80.1353 13.6072 79.2137 13.6072C78.2921 13.6072 77.6131 13.1586 77.3705 12.3611H74.3633C74.7028 14.6539 76.546 15.9498 79.2137 15.9498C81.7359 15.9498 83.6761 14.6041 83.6761 12.3112C83.6761 10.6664 82.6575 9.56982 80.7658 9.12123Z" />
            <path d="M89.3522 11.962V7.07734H91.7289V4.58515H89.3522V1.54468H86.3449V4.58515H84.6958V7.07734H86.3449V12.3608C86.3449 14.853 87.7516 15.9495 89.9342 15.9495C90.7588 15.9495 91.4864 15.8 91.8744 15.6006V12.8592C91.5349 13.1084 91.0013 13.258 90.5648 13.258C89.7887 13.2081 89.3522 12.8094 89.3522 11.962Z" />
            <path d="M98.3249 4.33643C95.1721 4.33643 92.6499 6.92831 92.6499 10.1682C92.6499 13.408 95.1721 15.9999 98.3249 15.9999C101.478 15.9999 104 13.408 104 10.1682C104 6.92831 101.478 4.33643 98.3249 4.33643ZM98.3249 12.9594C96.8213 12.9594 95.5602 11.6635 95.5602 10.1183C95.5602 8.57315 96.8213 7.27721 98.3249 7.27721C99.8285 7.27721 101.09 8.57315 101.09 10.1183C101.09 11.6635 99.8285 12.9594 98.3249 12.9594Z" />
            <path d="M70.9685 3.09032C71.7989 3.09032 72.4721 2.39853 72.4721 1.54516C72.4721 0.691791 71.7989 0 70.9685 0C70.138 0 69.4648 0.691791 69.4648 1.54516C69.4648 2.39853 70.138 3.09032 70.9685 3.09032Z" />
          </g>

          <g fill="#5A9C69">
            <path d="M45.0393 31.8994V24.9811C45.0393 24.218 45.2647 23.6101 45.7155 23.1572C46.1663 22.696 46.8091 22.4654 47.644 22.4654C47.9278 22.4654 48.1991 22.4948 48.4579 22.5535C48.725 22.6122 48.9504 22.7044 49.1341 22.8302L48.6207 24.2516C48.5122 24.1761 48.3911 24.1174 48.2576 24.0755C48.124 24.0335 47.9821 24.0126 47.8318 24.0126C47.548 24.0126 47.3267 24.0964 47.1681 24.2642C47.0179 24.4235 46.9427 24.6667 46.9427 24.9937V25.6226L46.9928 26.4654V31.8994H45.0393ZM44 26.7925V25.283H48.6708V26.7925H44Z" />
            <path d="M52.7641 32C52.0461 32 51.4075 31.8491 50.8482 31.5472C50.2972 31.2453 49.8589 30.8344 49.5333 30.3145C49.2161 29.7862 49.0575 29.1866 49.0575 28.5157C49.0575 27.8365 49.2161 27.2369 49.5333 26.717C49.8589 26.1887 50.2972 25.7778 50.8482 25.4843C51.4075 25.1824 52.0461 25.0314 52.7641 25.0314C53.4737 25.0314 54.1081 25.1824 54.6675 25.4843C55.2268 25.7778 55.6651 26.1845 55.9823 26.7044C56.2995 27.2243 56.4581 27.8281 56.4581 28.5157C56.4581 29.1866 56.2995 29.7862 55.9823 30.3145C55.6651 30.8344 55.2268 31.2453 54.6675 31.5472C54.1081 31.8491 53.4737 32 52.7641 32ZM52.7641 30.3899C53.0897 30.3899 53.3818 30.3145 53.6406 30.1635C53.8994 30.0126 54.104 29.7987 54.2542 29.522C54.4045 29.2369 54.4796 28.9015 54.4796 28.5157C54.4796 28.1216 54.4045 27.7862 54.2542 27.5094C54.104 27.2327 53.8994 27.0189 53.6406 26.8679C53.3818 26.717 53.0897 26.6415 52.7641 26.6415C52.4385 26.6415 52.1463 26.717 51.8875 26.8679C51.6287 27.0189 51.42 27.2327 51.2614 27.5094C51.1111 27.7862 51.036 28.1216 51.036 28.5157C51.036 28.9015 51.1111 29.2369 51.2614 29.522C51.42 29.7987 51.6287 30.0126 51.8875 30.1635C52.1463 30.3145 52.4385 30.3899 52.7641 30.3899Z" />
            <path d="M57.7639 31.8994V25.1321H59.6297V27.044L59.3667 26.4906C59.5671 26.0126 59.8885 25.652 60.3309 25.4088C60.7734 25.1572 61.3118 25.0314 61.9463 25.0314V26.8428C61.8628 26.8344 61.7877 26.8302 61.7209 26.8302C61.6541 26.8218 61.5831 26.8176 61.508 26.8176C60.9737 26.8176 60.5396 26.9727 60.2057 27.283C59.8801 27.5849 59.7173 28.0587 59.7173 28.7044V31.8994H57.7639Z" />
            <path d="M66.8408 31.8994V23.0943H71.1234C72.2254 23.0943 73.0518 23.304 73.6028 23.7233C74.1621 24.1426 74.4418 24.696 74.4418 25.3836C74.4418 25.8449 74.3291 26.2474 74.1037 26.5912C73.8783 26.9266 73.5694 27.1866 73.177 27.3711C72.7847 27.5556 72.3339 27.6478 71.8246 27.6478L72.0626 27.1321C72.6135 27.1321 73.1019 27.2243 73.5277 27.4088C73.9534 27.5849 74.2832 27.8491 74.5169 28.2013C74.759 28.5535 74.8801 28.9853 74.8801 29.4969C74.8801 30.2516 74.5837 30.8428 73.991 31.2704C73.3983 31.6897 72.5259 31.8994 71.3738 31.8994H66.8408ZM68.8569 30.3648H71.2236C71.7495 30.3648 72.146 30.2809 72.4132 30.1132C72.6887 29.9371 72.8264 29.6604 72.8264 29.283C72.8264 28.914 72.6887 28.6415 72.4132 28.4654C72.146 28.2809 71.7495 28.1887 71.2236 28.1887H68.7066V26.7044H70.873C71.3655 26.7044 71.7412 26.6205 72 26.4528C72.2671 26.2767 72.4007 26.0126 72.4007 25.6604C72.4007 25.3166 72.2671 25.0608 72 24.8931C71.7412 24.717 71.3655 24.6289 70.873 24.6289H68.8569V30.3648Z" />
            <path d="M79.1207 32C78.5614 32 78.0605 31.891 77.618 31.673C77.1839 31.4549 76.8458 31.1237 76.6037 30.6792C76.3616 30.2264 76.2406 29.652 76.2406 28.956V25.1321H78.194V28.6667C78.194 29.2285 78.3109 29.6436 78.5447 29.912C78.7868 30.1719 79.1249 30.3019 79.559 30.3019C79.8595 30.3019 80.1266 30.239 80.3604 30.1132C80.5941 29.979 80.7778 29.7778 80.9114 29.5094C81.0449 29.2327 81.1117 28.8889 81.1117 28.478V25.1321H83.0652V31.8994H81.2119V30.0377L81.55 30.5786C81.3246 31.0482 80.9907 31.4046 80.5482 31.6478C80.1141 31.8826 79.6383 32 79.1207 32Z" />
            <path d="M87.1415 32C86.5654 32 86.0103 31.9329 85.476 31.7987C84.9501 31.6562 84.5327 31.4801 84.2238 31.2704L84.8749 29.8616C85.1838 30.0545 85.547 30.2138 85.9644 30.3396C86.3901 30.457 86.8075 30.5157 87.2166 30.5157C87.6674 30.5157 87.9846 30.4612 88.1683 30.3522C88.3603 30.2432 88.4563 30.0922 88.4563 29.8994C88.4563 29.74 88.3812 29.6226 88.2309 29.5472C88.089 29.4633 87.897 29.4004 87.6549 29.3585C87.4128 29.3166 87.1456 29.2746 86.8535 29.2327C86.5696 29.1908 86.2816 29.1363 85.9894 29.0692C85.6972 28.9937 85.4301 28.8847 85.188 28.7421C84.9459 28.5996 84.7497 28.4067 84.5995 28.1635C84.4575 27.9203 84.3866 27.6059 84.3866 27.2201C84.3866 26.7925 84.5076 26.4151 84.7497 26.0881C85.0002 25.761 85.3591 25.5052 85.8266 25.3208C86.2941 25.1279 86.8535 25.0314 87.5046 25.0314C87.9638 25.0314 88.4312 25.0818 88.9071 25.1824C89.3829 25.283 89.7795 25.4298 90.0967 25.6226L89.4455 27.0189C89.12 26.826 88.7902 26.696 88.4563 26.6289C88.1307 26.5535 87.8135 26.5157 87.5046 26.5157C87.0705 26.5157 86.7533 26.5744 86.5529 26.6918C86.3526 26.8092 86.2524 26.9602 86.2524 27.1447C86.2524 27.3124 86.3233 27.4382 86.4653 27.522C86.6155 27.6059 86.8117 27.673 87.0538 27.7233C87.2959 27.7736 87.5589 27.8197 87.8427 27.8616C88.1349 27.8952 88.4271 27.9497 88.7193 28.0252C89.0114 28.1006 89.2744 28.2096 89.5082 28.3522C89.7503 28.4864 89.9464 28.6751 90.0967 28.9182C90.247 29.153 90.3221 29.4633 90.3221 29.8491C90.3221 30.2683 90.1969 30.6415 89.9464 30.9686C89.696 31.2872 89.3328 31.5388 88.857 31.7233C88.3895 31.9078 87.8177 32 87.1415 32Z" />
            <path d="M91.5274 31.8994V25.1321H93.4808V31.8994H91.5274ZM92.5041 24.1887C92.1451 24.1887 91.8529 24.0839 91.6275 23.8742C91.4021 23.6646 91.2894 23.4046 91.2894 23.0943C91.2894 22.7841 91.4021 22.5241 91.6275 22.3145C91.8529 22.1048 92.1451 22 92.5041 22C92.8631 22 93.1553 22.1006 93.3807 22.3019C93.6061 22.4948 93.7188 22.7463 93.7188 23.0566C93.7188 23.3836 93.6061 23.6562 93.3807 23.8742C93.1636 24.0839 92.8714 24.1887 92.5041 24.1887Z" />
            <path d="M99.3886 25.0314C99.9229 25.0314 100.399 25.1405 100.816 25.3585C101.242 25.5681 101.576 25.8952 101.818 26.3396C102.06 26.7757 102.181 27.3375 102.181 28.0252V31.8994H100.228V28.327C100.228 27.782 100.107 27.3795 99.8644 27.1195C99.6307 26.8595 99.2967 26.7296 98.8626 26.7296C98.5538 26.7296 98.2741 26.7966 98.0237 26.9308C97.7816 27.0566 97.5896 27.2537 97.4476 27.522C97.3141 27.7904 97.2473 28.1342 97.2473 28.5535V31.8994H95.2938V25.1321H97.1596V27.0063L96.809 26.4403C97.0511 25.9874 97.3975 25.6394 97.8483 25.3962C98.2991 25.153 98.8125 25.0314 99.3886 25.0314Z" />
            <path d="M107.344 32C106.576 32 105.9 31.8491 105.315 31.5472C104.739 31.2453 104.293 30.8344 103.976 30.3145C103.658 29.7862 103.5 29.1866 103.5 28.5157C103.5 27.8365 103.654 27.2369 103.963 26.717C104.28 26.1887 104.71 25.7778 105.253 25.4843C105.796 25.1824 106.409 25.0314 107.094 25.0314C107.753 25.0314 108.346 25.174 108.872 25.4591C109.406 25.7358 109.828 26.1384 110.137 26.6667C110.445 27.1866 110.6 27.8113 110.6 28.5409C110.6 28.6164 110.596 28.7044 110.587 28.805C110.579 28.8973 110.571 28.9853 110.562 29.0692H105.09V27.9245H109.535L108.784 28.2642C108.784 27.9119 108.713 27.6059 108.571 27.3459C108.429 27.086 108.233 26.8847 107.983 26.7421C107.732 26.5912 107.44 26.5157 107.106 26.5157C106.772 26.5157 106.476 26.5912 106.217 26.7421C105.967 26.8847 105.77 27.0901 105.629 27.3585C105.487 27.6184 105.416 27.9287 105.416 28.2893V28.5912C105.416 28.9602 105.495 29.2872 105.654 29.5723C105.821 29.8491 106.05 30.0629 106.342 30.2138C106.643 30.3564 106.993 30.4277 107.394 30.4277C107.753 30.4277 108.066 30.3732 108.333 30.2642C108.609 30.1551 108.859 29.9916 109.085 29.7736L110.124 30.9057C109.815 31.2579 109.427 31.5304 108.959 31.7233C108.492 31.9078 107.954 32 107.344 32Z" />
            <path d="M114.167 32C113.591 32 113.036 31.9329 112.501 31.7987C111.976 31.6562 111.558 31.4801 111.249 31.2704L111.9 29.8616C112.209 30.0545 112.572 30.2138 112.99 30.3396C113.416 30.457 113.833 30.5157 114.242 30.5157C114.693 30.5157 115.01 30.4612 115.194 30.3522C115.386 30.2432 115.482 30.0922 115.482 29.8994C115.482 29.74 115.407 29.6226 115.256 29.5472C115.114 29.4633 114.922 29.4004 114.68 29.3585C114.438 29.3166 114.171 29.2746 113.879 29.2327C113.595 29.1908 113.307 29.1363 113.015 29.0692C112.723 28.9937 112.456 28.8847 112.213 28.7421C111.971 28.5996 111.775 28.4067 111.625 28.1635C111.483 27.9203 111.412 27.6059 111.412 27.2201C111.412 26.7925 111.533 26.4151 111.775 26.0881C112.026 25.761 112.385 25.5052 112.852 25.3208C113.32 25.1279 113.879 25.0314 114.53 25.0314C114.989 25.0314 115.457 25.0818 115.933 25.1824C116.408 25.283 116.805 25.4298 117.122 25.6226L116.471 27.0189C116.145 26.826 115.816 26.696 115.482 26.6289C115.156 26.5535 114.839 26.5157 114.53 26.5157C114.096 26.5157 113.779 26.5744 113.578 26.6918C113.378 26.8092 113.278 26.9602 113.278 27.1447C113.278 27.3124 113.349 27.4382 113.491 27.522C113.641 27.6059 113.837 27.673 114.079 27.7233C114.321 27.7736 114.584 27.8197 114.868 27.8616C115.16 27.8952 115.453 27.9497 115.745 28.0252C116.037 28.1006 116.3 28.2096 116.534 28.3522C116.776 28.4864 116.972 28.6751 117.122 28.9182C117.272 29.153 117.348 29.4633 117.348 29.8491C117.348 30.2683 117.222 30.6415 116.972 30.9686C116.721 31.2872 116.358 31.5388 115.882 31.7233C115.415 31.9078 114.843 32 114.167 32Z" />
            <path d="M120.819 32C120.243 32 119.688 31.9329 119.154 31.7987C118.628 31.6562 118.211 31.4801 117.902 31.2704L118.553 29.8616C118.862 30.0545 119.225 30.2138 119.642 30.3396C120.068 30.457 120.485 30.5157 120.894 30.5157C121.345 30.5157 121.663 30.4612 121.846 30.3522C122.038 30.2432 122.134 30.0922 122.134 29.8994C122.134 29.74 122.059 29.6226 121.909 29.5472C121.767 29.4633 121.575 29.4004 121.333 29.3585C121.091 29.3166 120.824 29.2746 120.531 29.2327C120.248 29.1908 119.959 29.1363 119.667 29.0692C119.375 28.9937 119.108 28.8847 118.866 28.7421C118.624 28.5996 118.428 28.4067 118.277 28.1635C118.135 27.9203 118.064 27.6059 118.064 27.2201C118.064 26.7925 118.186 26.4151 118.428 26.0881C118.678 25.761 119.037 25.5052 119.505 25.3208C119.972 25.1279 120.531 25.0314 121.182 25.0314C121.642 25.0314 122.109 25.0818 122.585 25.1824C123.061 25.283 123.457 25.4298 123.775 25.6226L123.123 27.0189C122.798 26.826 122.468 26.696 122.134 26.6289C121.809 26.5535 121.491 26.5157 121.182 26.5157C120.748 26.5157 120.431 26.5744 120.231 26.6918C120.03 26.8092 119.93 26.9602 119.93 27.1447C119.93 27.3124 120.001 27.4382 120.143 27.522C120.293 27.6059 120.49 27.673 120.732 27.7233C120.974 27.7736 121.237 27.8197 121.521 27.8616C121.813 27.8952 122.105 27.9497 122.397 28.0252C122.689 28.1006 122.952 28.2096 123.186 28.3522C123.428 28.4864 123.624 28.6751 123.775 28.9182C123.925 29.153 124 29.4633 124 29.8491C124 30.2683 123.875 30.6415 123.624 30.9686C123.374 31.2872 123.011 31.5388 122.535 31.7233C122.067 31.9078 121.496 32 120.819 32Z" />
          </g>
        </>
      ) : (
        ''
      )}

      <path d="M10.6667 10.6667V32H21.3335V0H0V10.6667H10.6667Z" fill={color} />

      <path
        d="M21.3335 0C21.3335 5.92 26.1335 10.6667 32.0002 10.6667V0H21.3335Z"
        fill="#009D4E"
      />
      <path
        d="M21.3335 0C21.3335 5.92 26.1335 10.6667 32.0002 10.6667V0H21.3335Z"
        fill="url(#green-gradient)"
      />
    </SvgIcon>
  )
}
