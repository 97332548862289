import { css } from '@emotion/react'

export const twistoLogoColor = '#4e0f89'
export const zipLogoColor = '#1A0826'
export const zipInverseLogoColor = '#FFFFFA'

export const root = css`
  width: auto;
  overflow: visible;
`

export const sizes = {
  medium: css`
    height: 1em;
  `,
  large: css`
    height: 32px;
  `,
}
