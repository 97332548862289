import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { gradients } from './constants'

export const root = css`
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
`

export const colors = {
  inherit: () => css`
    fill: currentColor;
  `,
  primary: () => css`
    fill: url(#${gradients.primary});
  `,
  secondary: () => css`
    fill: url(#${gradients.secondary});
  `,
  actionPrimary: () => css`
    fill: url(#${gradients.actionPrimary});
  `,
  success: (theme: Theme) => css`
    fill: ${theme.palette.success[500]};
  `,
  warning: (theme: Theme) => css`
    fill: ${theme.palette.warning[500]};
  `,
  error: (theme: Theme) => css`
    fill: ${theme.palette.error[500]};
  `,
  gray: (theme: Theme) => css`
    fill: ${theme.palette.neutral[700]};
  `,
  white: (theme: Theme) => css`
    fill: ${theme.palette.other.white};
  `,
}

export const sizes = {
  '12': css`
    font-size: 12px;
  `,
  '16': css`
    font-size: 16px;
  `,
  '18': css`
    font-size: 18px;
  `,
  '20': css`
    font-size: 20px;
  `,
  '24': css`
    font-size: 24px;
  `,
  '30': css`
    font-size: 30px;
  `,
  '32': css`
    font-size: 32px;
  `,
  '40': css`
    font-size: 40px;
  `,
  '48': css`
    font-size: 48px;
  `,
  '56': css`
    font-size: 56px;
  `,
  '80': css`
    font-size: 80px;
  `,
  '96': css`
    font-size: 96px;
  `,
  inherit: css`
    font-size: inherit;
  `,
}
