import { useTheme } from '@emotion/react'

import { TwistoBusinessLogo } from './twisto-business-logo'
import { TwistoLogo } from './twisto-logo'

export type LogoProps = {
  className?: string
  variant?: 'icon' | 'full'
  inverse?: boolean
  size?: 'medium' | 'large'
  logo?: 'twisto' | 'twisto-business' | undefined
}

export const Logo = ({
  variant = 'icon',
  inverse = false,
  size,
  className,
  logo,
}: LogoProps) => {
  const Component = logo === 'twisto-business' ? TwistoBusinessLogo : TwistoLogo
  const { mode } = useTheme()

  return (
    <Component
      className={className}
      data-testid="logo"
      inverse={inverse || mode === 'dark'}
      size={size}
      variant={variant}
    />
  )
}
