import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = css`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: ${styles.fontSize.lg};
`
