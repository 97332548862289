import { cloneElement } from 'react'
import type { ReactElement } from 'react'

import { Typography } from '@twisto/components/atoms/typography'
import { useIsMobile } from '@twisto/hooks'
import {
  CheckmarkOutlineIcon,
  CloseIcon,
  InfoIcon,
  WarningOutlineIcon,
} from '@twisto/icons'

import * as styles from './toast-message.styles'
import type { Message, Variant } from './toast.context'

const iconsMap: Record<Variant, ReactElement> = {
  success: <CheckmarkOutlineIcon color="success" />,
  warning: <WarningOutlineIcon color="warning" />,
  error: <WarningOutlineIcon color="error" />,
  info: <InfoIcon color="gray" />,
}

type Props = {
  message: Message
  variant: Variant
  onClose: () => void
}

export const ToastMessage = ({ message, variant, onClose }: Props) => {
  const isMobile = useIsMobile()
  const icon = cloneElement(iconsMap[variant], {
    size: isMobile ? '24' : '32',
  })

  return (
    <div
      css={(theme) => [styles.variants[variant](theme), styles.box]}
      data-testid="toast-message"
    >
      {icon}

      <Typography color="textPrimary" variant="b2">
        {message}
      </Typography>

      <button
        css={styles.button}
        onClick={(event) => {
          event.preventDefault()
          onClose()
        }}
      >
        <CloseIcon color="gray" size="16" />
      </button>
    </div>
  )
}
