import type { HTMLAttributes, ReactNode } from 'react'

import type { TypographyProps } from '@twisto/components/atoms/typography'
import { Typography } from '@twisto/components/atoms/typography'

type Props = {
  children: ReactNode
} & HTMLAttributes<HTMLParagraphElement> &
  TypographyProps

export const ModalContentText = ({ children, ...other }: Props) => (
  <Typography data-testid="modal-content-text" variant="h3" {...other}>
    {children}
  </Typography>
)
