import { useEffect, useState } from 'react'

const DEFAULT_POSITION = 0

let requestId: number

export const useScrollPosition = (element?: HTMLDivElement | null) => {
  const [scrollPosition, setScrollPosition] = useState(DEFAULT_POSITION)
  useEffect(() => {
    let lastPosition = DEFAULT_POSITION
    let ticking = false
    const scrollElement = element ?? window

    const handleAnimationFrame = () => {
      setScrollPosition(lastPosition)
      ticking = false
    }

    const handleScroll = () => {
      lastPosition = element?.scrollTop ?? window.scrollY

      if (!ticking) {
        requestId = window.requestAnimationFrame(handleAnimationFrame)
        ticking = true
      }
    }

    scrollElement.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.cancelAnimationFrame(requestId)
      scrollElement.removeEventListener('scroll', handleScroll)
    }
  }, [element])

  return scrollPosition
}
