import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, duration, size } from '@twisto/styles'

import doubleArrow from './double-arrow.svg'
import paramDoubleArrow from './param-double-arrow.svg'

export const THUMB_WIDTH = 28
const THUMB_HEIGHT = 24
const SLIDER_LINE_HEIGHT = 8

const arrowImage: Record<Theme['themeName'], string> = {
  param: paramDoubleArrow.src,
  twisto: doubleArrow.src,
}

const sliderThumbStyle = (theme: Theme) => `
  appearance: none;
  width: ${THUMB_WIDTH}px;
  height: ${THUMB_HEIGHT}px;
  background: no-repeat ${theme.palette.other.white} url(${
    arrowImage[theme.themeName]
  }) center;
  border: 1px solid ${theme.palette.neutral[300]};
  transition: border ${duration.shortest}ms ease-out;
  box-shadow: 0 ${size[4]} ${size[4]} 0 rgba(36, 34, 42, 0.08);
  border-radius: ${borderRadius.sm};
`

const focusRuleSet = (theme: Theme) => `
  border: 1px solid ${theme.palette.brand[500]};
  transition: border ${duration.shortest}ms ease-out;
`

const backgroundStyle = (theme: Theme) => `
  background: ${theme.palette.brand[500]};
  background-repeat: no-repeat;
  border-radius: ${SLIDER_LINE_HEIGHT / 2}px;
`

export const wrapper = css`
  position: relative;
  width: 100%;
`

export const container = (theme: Theme) => css`
  width: 100%;
  padding: 0 ${THUMB_WIDTH / 2}px;
  margin: ${(THUMB_HEIGHT - SLIDER_LINE_HEIGHT) / 2}px 0;
  ${backgroundStyle(theme)};
  background: ${theme.palette.brand[100]};
  background-image: none;
`

export const slider = (theme: Theme) => css`
  /*style declaration for IE, Edge */
  padding: 0;
  margin: 0;
  cursor: pointer;

  height: 70px;
  width: 100%;

  /* style for rest browsers */
  @supports not (-ms-user-select: none) {
    appearance: none;
    height: ${SLIDER_LINE_HEIGHT}px;
    margin: ${(THUMB_HEIGHT - SLIDER_LINE_HEIGHT) / 2}px 0;

    ${backgroundStyle(theme)}
    background: ${theme.palette.brand[100]};
    background-repeat: no-repeat;
    background-image: linear-gradient(
      0deg,
      ${theme.palette.brand[500]} 0%,
      ${theme.palette.brand[500]} 100%
    );
  }

  &::-webkit-slider-thumb {
    ${sliderThumbStyle(theme)}
  }

  &::-moz-range-thumb {
    ${sliderThumbStyle(theme)}
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-ms-thumb {
    ${sliderThumbStyle(theme)}
  }

  &::-ms-fill-lower {
    background: ${theme.palette.action[500]};
  }

  &::-ms-fill-upper {
    background: ${theme.palette.brand[100]};
  }

  &::-ms-track {
    color: rgba(0, 0, 0, 0);
    height: ${size[8]};
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: ${size[4]};
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus::-webkit-slider-thumb {
    ${focusRuleSet(theme)}
  }
  &:focus::-moz-range-thumb {
    ${focusRuleSet(theme)}
  }
  &:focus::-ms-thumb {
    ${focusRuleSet(theme)}
  }
`

export const rangeSlider = css`
  position: relative;
  width: 100%;
  height: ${SLIDER_LINE_HEIGHT}px;
  box-sizing: content-box;
  cursor: pointer;
  user-select: none;
  /* hack for disabling highlight on mobile */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
`

export const rail = css`
  width: 100%;
  height: 100%;
`

export const track = (theme: Theme) => css`
  height: ${SLIDER_LINE_HEIGHT}px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${backgroundStyle(theme)};
`

export const sliderThumb = (theme: Theme) => css`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);

  ${sliderThumbStyle(theme)}

  &:focus {
    ${focusRuleSet(theme)}
  }
`

export const footer = css`
  margin-top: ${size[4]};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`
