import type { SpringConfig } from '@react-spring/core'

export const animationConfig: SpringConfig = {
  tension: 220,
}

/**
 * more information about the config properties in the docs
 * https://react-spring.dev/common/configs#configs
 */
