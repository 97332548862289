import type { ModalA11yProps } from '../types'

export const getModalProps = <T extends ModalA11yProps>(props: T) => {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    'aria-describedby': ariaDescribedby,
    ...restProps
  } = props

  // provides a11y props required for `DialogContent`
  const a11yProps: ModalA11yProps =
    ariaLabelledby !== undefined
      ? {
          'aria-labelledby': ariaLabelledby,
          'aria-describedby': ariaDescribedby,
        }
      : {
          'aria-label': ariaLabel,
          'aria-describedby': ariaDescribedby,
        }

  return {
    a11yProps,
    restProps,
  }
}
