import { type } from 'ramda'

export const isObject = (value: unknown): value is Record<string, unknown> =>
  type(value) === 'Object'

export const isNumber = (value: unknown): value is number =>
  type(value) === 'Number'

export const isString = (value: unknown): value is string =>
  type(value) === 'String'

// also tests that value is not empty
export const hasString = (value: unknown): value is string =>
  isString(value) && value.length > 0

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  type(value) === 'Function' || type(value) === 'AsyncFunction'

export function isArray<T>(
  value: unknown | readonly unknown[]
): value is readonly T[]
export function isArray<T>(value: unknown): value is T[] {
  return type(value) === 'Array'
}

export const isBoolean = (value: unknown): value is boolean =>
  type(value) === 'Boolean'

export const isPromise = <T = unknown>(value: unknown): value is Promise<T> =>
  isObject(value) && 'then' in value && typeof value.then === 'function'

export const hasKey = <K extends string>(
  value: unknown,
  key: K
): value is Record<string, unknown> & { [k in K]: unknown } =>
  isObject(value) && key in value
