import type { ReactNode } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

import { AppError } from './app-error'
import type { ModalErrorProps } from './error-modal'
import { ErrorModal } from './error-modal'
import { showDefaultError, showErrorWithContinue } from './error-modal-builder'

type ErrorModalApi = Record<'showDefaultError', (message?: string) => void> & {
  showErrorWithContinue: (
    message: string,
    onContinueCallback?: () => void,
    perex?: string | null
  ) => void
  showError: (props: ModalErrorProps) => void
}

export const ErrorModalContext = createContext<null | ErrorModalApi>(null)

export const useErrorModal = () => {
  const context = useContext(ErrorModalContext)

  if (!context) {
    throw new Error('useErrorModal must be used within a ErrorModalProvider')
  }

  return context
}

const errorSetter =
  (setter: (error: ModalErrorProps) => void) =>
  (getter: (message?: string) => ModalErrorProps) =>
  (message?: string) =>
    setter(getter(message))

type ProviderProps = {
  children: ReactNode
}

export const ErrorModalProvider = ({ children }: ProviderProps) => {
  const [error, setError] = useState<ModalErrorProps>({})

  const api: ErrorModalApi = useMemo(
    () => ({
      showError: (error: unknown) => {
        if (error instanceof AppError && error.modalErrorProps) {
          return setError({ ...showDefaultError(), ...error.modalErrorProps })
        }

        setError(showDefaultError())
      },
      showDefaultError: errorSetter(setError)(showDefaultError),
      showErrorWithContinue: (message, onContinueCallback, perex) =>
        setError(
          showErrorWithContinue(
            () => {
              setError({})

              if (onContinueCallback) {
                onContinueCallback()
              }
            },
            message,
            perex
          )
        ),
    }),
    []
  )

  return (
    <ErrorModalContext.Provider value={api}>
      <ErrorModal {...error} />
      {children}
    </ErrorModalContext.Provider>
  )
}
