import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

const animations = {
  scale: styles.createAnimation('scaleLoader', {
    '100%': {
      transform: 'scale(1)',
    },
  }),
  rotate: styles.createAnimation('rotateImage', {
    '100%': {
      transform: 'rotate(360deg)',
    },
  }),
}

export const image = css`
  max-width: 100%;
  max-height: 100%;

  font-size: 60px;

  animation: ${animations.rotate.name} ${styles.duration.longer}ms
    ${styles.easing.easeInOut} infinite;

  ${animations.rotate.definition};
`

export const imageWrapper = css`
  display: flex;
  transform: scale(1.5);
  animation: ${animations.scale.name} ${styles.duration.shorter}ms
    ${styles.easing.easeOut} forwards;

  ${animations.scale.definition}
`
