import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { i18n } from '@lingui/core'
import { I18nProvider as I18nLinguiProvider } from '@lingui/react'

import type { TranslationBundle } from './setup-i18n'
import { setupI18n } from './setup-i18n'

export type I18nLanguage = GLOBALS.Language

type Props = {
  children: ReactNode
  language: I18nLanguage
  translationBundle?: TranslationBundle
}

export const I18nProvider = ({
  language,
  translationBundle,
  children,
}: Props) => {
  useEffect(() => {
    // load current language locale
    setupI18n({ language, translationBundle })
  }, [language, translationBundle])

  return (
    <I18nLinguiProvider
      defaultComponent={({ children }) => <span>{children}</span>}
      i18n={i18n}
    >
      {children}
    </I18nLinguiProvider>
  )
}
