import { css } from '@emotion/react'

import type { RuleSetMap } from '../helpers'
import { createDirectionalPropertyCss } from '../helpers'

const cssRuleset = {
  top: createDirectionalPropertyCss('margin', 'top'),
  bottom: createDirectionalPropertyCss('margin', 'bottom'),
  left: createDirectionalPropertyCss('margin', 'left'),
  right: createDirectionalPropertyCss('margin', 'right'),
  horizontal: createDirectionalPropertyCss('margin', 'right'),
  vertical: createDirectionalPropertyCss('margin', 'right'),
} as const

export const top: RuleSetMap = {
  xs: css`
    ${cssRuleset.top.xs}
  `,
  sm: css`
    ${cssRuleset.top.sm}
  `,
  smMd: css`
    ${cssRuleset.top.smMd}
  `,
  md: css`
    ${cssRuleset.top.md}
  `,
  lg: css`
    ${cssRuleset.top.lg}
  `,
  xl: css`
    ${cssRuleset.top.xl}
  `,
} as const

export const right: RuleSetMap = {
  xs: css`
    ${cssRuleset.right.xs}
  `,
  sm: css`
    ${cssRuleset.right.sm}
  `,
  smMd: css`
    ${cssRuleset.right.smMd}
  `,
  md: css`
    ${cssRuleset.right.md}
  `,
  lg: css`
    ${cssRuleset.right.lg}
  `,
  xl: css`
    ${cssRuleset.right.xl}
  `,
} as const

export const bottom: RuleSetMap = {
  xs: css`
    ${cssRuleset.bottom.xs}
  `,
  sm: css`
    ${cssRuleset.bottom.sm}
  `,
  smMd: css`
    ${cssRuleset.bottom.smMd}
  `,
  md: css`
    ${cssRuleset.bottom.md}
  `,
  lg: css`
    ${cssRuleset.bottom.lg}
  `,
  xl: css`
    ${cssRuleset.bottom.xl}
  `,
} as const

export const left: RuleSetMap = {
  xs: css`
    ${cssRuleset.left.xs}
  `,
  sm: css`
    ${cssRuleset.left.sm}
  `,
  smMd: css`
    ${cssRuleset.left.smMd}
  `,
  md: css`
    ${cssRuleset.left.md}
  `,
  lg: css`
    ${cssRuleset.left.lg}
  `,
  xl: css`
    ${cssRuleset.left.xl}
  `,
} as const
