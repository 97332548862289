import type { HTMLAttributes, ReactElement } from 'react'

import * as styles from './modal-image.styles'

type Props = {
  children: ReactElement
} & HTMLAttributes<HTMLDivElement>

export const ModalImage = ({ children, ...other }: Props) => (
  <div css={styles.elementImage} data-testid="modal-image" {...other}>
    {children}
  </div>
)
