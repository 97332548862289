import type { ReactNode } from 'react'

import { Typography } from '@twisto/components/atoms/typography'
import type { typographyVariant } from '@twisto/components/atoms/typography'
import { useBreakpoint } from '@twisto/hooks'

import type { Sizes } from './text-button.styles'

type TextButtonTypographyVariant = keyof Pick<
  typeof typographyVariant,
  'b1' | 'b2' | 'b3'
>

type Props = {
  size: Sizes
  noTypography: boolean
  children: ReactNode
}

export const TextButtonTypography = ({
  size,
  noTypography,
  children,
}: Props) => {
  const isDesktop = useBreakpoint('mdUp')

  const typographyMap: Record<Sizes, TextButtonTypographyVariant> = {
    small: 'b3',
    medium: 'b2',
    // mobile version does not have a large variant, so we reapply medium variant typography
    large: isDesktop ? 'b1' : 'b2',
  }

  if (noTypography) {
    return <>{children}</>
  }

  return (
    <Typography
      component="span"
      fontWeight="medium"
      variant={typographyMap[size]}
    >
      {children}
    </Typography>
  )
}
