import czRegionConfig, { czCustomerConfig, czDocs, czUrls } from './cz'
import plRegionConfig, { plCustomerConfig, plDocs, plUrls } from './pl'
import type { FeatureFlags as Features } from './types'

export const regionObjects = {
  CZ: czRegionConfig,
  PL: plRegionConfig,
}

export const docsObjects = {
  CZ: czDocs,
  PL: plDocs,
}

export const urlsObjects = {
  CZ: czUrls,
  PL: plUrls,
}

export const customerConfigObjects = {
  CZ: czCustomerConfig,
  PL: plCustomerConfig,
}

export type FeatureFlags = Features
