import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const container = css`
  position: fixed;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${styles.zIndex.modal};
  padding: ${styles.size[8]};
  pointer-events: auto;

  /* fixes the issue with overflowing slide animation */
  max-width: 100vw;
  overflow-x: hidden;

  ${styles.breakpoint.up('sm')} {
    padding: ${styles.size[16]};
  }
`

export const centered = css`
  display: grid;
  align-items: center;
`

export const overlay = (theme: Theme) => css`
  background: ${theme.palette.other.overlay};
`
