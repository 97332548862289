import type { AnchorHTMLAttributes, MouseEvent, ReactNode } from 'react'

import { useAtom } from 'jotai'
import NextLink from 'next/link'
import { isNil } from 'ramda'

import { locationStateAtom } from '@twisto/hooks'

type Props = {
  to?: string
  children?: ReactNode
  className?: string
  replace?: boolean
  locationState?: Record<string, unknown>
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const BaseLink = ({
  to,
  children,
  className,
  replace,
  locationState,
  ...other
}: Props) => {
  const [, setLocationState] = useAtom(locationStateAtom)

  if (!isNil(to)) {
    const toWithoutBasepath = to.replace(new RegExp(`^${window.BASEPATH}`), '')

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
      setLocationState(locationState)
      if (other.onClick) {
        other.onClick(event)
      }
    }

    return (
      <NextLink
        data-testid="base-link"
        {...other}
        className={className}
        href={toWithoutBasepath}
        legacyBehavior={false}
        replace={replace}
        onClick={handleClick}
      >
        {children}
      </NextLink>
    )
  }

  return (
    <a
      className={className}
      data-testid="base-link"
      {...other}
      {...(other.target === '_blank' && { rel: 'noopener noreferrer' })}
    >
      {children}
    </a>
  )
}
