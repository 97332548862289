import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, size } from '@twisto/styles'

export const container = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  transition: opacity 50ms ease-in-out;
`

export const visible = css`
  opacity: 1;
`

export const bubble = css`
  position: absolute;
  bottom: ${size[4]};
  left: 0;
  width: 0;
`

export const arrow = (theme: Theme) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  fill: ${theme.palette.brand[100]};
`

export const text = (theme: Theme) => css`
  display: flex;
  user-select: none;
  position: absolute;
  bottom: ${size[4]};
  left: 0;
  transform: translateX(-50%);
  align-items: center;
  height: ${size[24]};
  min-width: ${size[24]};
  background-color: ${theme.palette.brand[100]};
  padding: 0 ${size[8]};
  border-radius: ${borderRadius.sm};
`
