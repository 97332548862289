import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

const animations = {
  show: styles.createAnimation('showLoader', {
    '100%': {
      opacity: 1,
    },
  }),
}

export const root = (theme: Theme) => css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${theme.palette.background
    .primary}cc; //adding opacity to background
  cursor: wait;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  animation: ${animations.show.name} ${styles.duration.shorter}ms
    ${styles.easing.easeOut} forwards;

  ${animations.show.definition}
`

export const layers = {
  default: css`
    z-index: ${styles.zIndex.loader};
  `,
  modal: css`
    z-index: ${styles.zIndex.modalLoader};
  `,
}

export const variants = {
  default: css`
    padding: ${styles.size[8]};
  `,
  compact: css`
    padding-top: ${styles.size[24]};
    padding-bottom: ${styles.size[24]};
    align-items: flex-start;
  `,
}

export const grayBackground = (theme: Theme) => css`
  background: ${theme.palette.background.secondary};
`
