import type { ReactNode } from 'react'

import * as styles from './headline.styles'
import { SizeProvider } from './size-context'

export type Align = 'start' | 'center'
export type Size = 'default' | 'large'

type Props = {
  children: ReactNode
  align?: Align
  size?: Size
  className?: string
}

export const Headline = ({
  children,
  align = 'center',
  size = 'default',
  className,
}: Props) => (
  <header
    className={className}
    css={[styles.container, styles.align[align]]}
    data-testid="headline"
  >
    <SizeProvider size={size}>{children}</SizeProvider>
  </header>
)
