export const drawer = {
  collapsedWidth: {
    sm: 60,
    lg: 80,
  },
  expandedWidth: {
    sm: 380,
    md: 420,
    lg: 480,
    xl: 720,
  },
} as const
