import * as styles from './loader.styles'
import { TwistoLoader } from './twisto-loader'

type Props = {
  variant?: keyof typeof styles.variants
  layer?: keyof typeof styles.layers
  grayBackground?: boolean
}

export const Loader = ({
  variant = 'default',
  layer = 'default',
  grayBackground = false,
}: Props) => {
  const loaderCss = [
    styles.root,
    styles.variants[variant],
    styles.layers[layer],
    grayBackground && styles.grayBackground,
  ]

  return (
    <div css={loaderCss} data-testid="loader">
      <TwistoLoader />
    </div>
  )
}
