import type { ComponentProps, ReactNode } from 'react'

import { Typography } from '@twisto/components/atoms/typography'

import type { Size } from './headline'
import { useSize } from './size-context'

type Props = {
  children: ReactNode
  className?: string
  titleId?: string
}

export const HeadlineTitle = ({ children, className, titleId }: Props) => {
  const mainSize = useSize()

  const variant: Record<Size, ComponentProps<typeof Typography>['variant']> = {
    large: 'h2',
    default: 'h3',
  }

  return (
    <Typography
      className={className}
      color="textPrimary"
      data-testid="headline-title"
      fontWeight="medium"
      id={titleId}
      variant={variant[mainSize]}
    >
      {children}
    </Typography>
  )
}
