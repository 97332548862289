import type { ReactNode, SVGProps } from 'react'

import { utils } from '@twisto/icons'

import * as styles from './svg-icon.styles'

type Props = {
  /**
   * Node passed into the SVG element.
   */
  children: ReactNode
  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess?: string
  className?: string
  /**
   * Applies a color attribute to the SVG element.
   */
  nativeColor?: string
} & SVGProps<SVGSVGElement>

export const SvgIcon = ({
  children,
  titleAccess,
  viewBox = '0 0 24 24',
  fontSize = 'default',
  nativeColor = 'inherit',
  ...other
}: Props) => {
  const a11yProps = utils.createIconA11yProps({ titleAccess })

  return (
    <svg
      color={nativeColor}
      css={styles.root}
      data-testid="svg-icon"
      focusable="false"
      fontSize={fontSize}
      viewBox={viewBox}
      {...a11yProps}
      {...other}
    >
      {children}
      {titleAccess !== undefined ? <title>{titleAccess}</title> : null}
    </svg>
  )
}

export default SvgIcon
