import type { Theme, jsx } from '@emotion/react'

import * as styles from './typography.styles'

export type TypographyProps = {
  color?: keyof ReturnType<typeof styles.color>
  component?: 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  fontWeight?: keyof typeof styles.fontWeight
  variant?: keyof typeof styles.typographyVariant
  align?: 'start' | 'center' | 'end'
  inline?: boolean
  noWrap?: boolean
  textTransform?: keyof typeof styles.textTransform
  // TODO: Deprecate gutterBottom
  gutterBottom?: boolean
  className?: string
} & jsx.JSX.IntrinsicElements['div']

const componentMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  b1: 'p',
  b2: 'p',
  b3: 'p',
  b4: 'p',
  b5: 'p',
} as const

export const Typography = ({
  children,
  color,
  component,
  fontWeight = 'regular',
  variant = 'b1',
  align,
  inline = false,
  noWrap = false,
  textTransform,
  gutterBottom = false,
  ...other
}: TypographyProps) => {
  const Component = component ?? componentMap[variant]

  const componentStyles = (theme: Theme) => [
    styles.root,
    styles.typographyVariant[variant],
    styles.fontWeight[fontWeight],
    color && styles.color(theme)[color],
    align && styles.align(align),
    textTransform && styles.textTransform[textTransform],
    gutterBottom && styles.gutterBottom,
    inline && styles.inline,
    noWrap && styles.noWrap,
  ]

  return (
    <Component css={componentStyles} data-testid="typography" {...other}>
      {children}
    </Component>
  )
}
