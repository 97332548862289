import { useCallback, useState } from 'react'

export const useOpen = (initialValue = false) => {
  const [state, setState] = useState(initialValue)

  const handleOpen = useCallback(() => setState(true), [])
  const handleClose = useCallback(() => setState(false), [])

  return {
    isOpen: state,
    handleOpen,
    handleClose,
  }
}
