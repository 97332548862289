export type DomainSettings = {
  environment:
    | 'production'
    | 'staging'
    | 'integration'
    | 'development'
    | 'testserver'
    | 'stage'
  baseDomain: string
  host: string
  region: GLOBALS.Region | null
}

export const getDomainSettings = (host: string): DomainSettings => {
  const matches = /^(?:https?:\/\/)?([^:]+):?(\d+)?$/.exec(host)
  if (matches === null) {
    throw new Error('invalid host')
  }
  const [, hostname, port = undefined] = matches

  if (hostname.endsWith('twisto.cz')) {
    return {
      environment: 'production',
      baseDomain: 'twisto.cz',
      region: 'CZ',
      host: 'www.twisto.cz',
    }
  }
  if (hostname.endsWith('twisto.pl')) {
    return {
      environment: 'production',
      baseDomain: 'twisto.pl',
      region: 'PL',
      host: 'www.twisto.pl',
    }
  }

  // match https://www.cz.staging.twisto.wtf
  const stagingMatch = /(\w+)\.staging\.twisto\.wtf$/.exec(hostname)
  if (stagingMatch) {
    return {
      environment: 'staging',
      baseDomain: stagingMatch[0],
      region: stagingMatch[1].toUpperCase() as GLOBALS.Region,
      host,
    }
  }
  // match https://www.cz.twisto.live
  const stageMatch = /(\w+)\.twisto\.live$/.exec(hostname)
  if (stageMatch) {
    return {
      environment: 'stage',
      baseDomain: stageMatch[0],
      region: stageMatch[1].toUpperCase() as GLOBALS.Region,
      host,
    }
  }

  const testServerMatch = /(\w+)\.([^.]+)\.ts\.twisto\.wtf$/.exec(hostname)
  if (testServerMatch) {
    return {
      environment: 'testserver',
      baseDomain: testServerMatch[0],
      region: testServerMatch[1].toUpperCase() as GLOBALS.Region,
      host,
    }
  }

  const developmentMatch = /(\w+)\.twisto.top$/.exec(hostname)
  if (developmentMatch) {
    // integration runs in Django
    const isIntegration = port == null
    let baseDomain = developmentMatch[0]
    if (port != null) {
      baseDomain += `:${port}`
    }

    return {
      environment: isIntegration ? 'integration' : 'development',
      baseDomain,
      region: developmentMatch[1].toUpperCase() as GLOBALS.Region,
      host,
    }
  }

  return {
    environment: 'development',
    baseDomain: host,
    region: null,
    host,
  }
}
