import { Global } from '@emotion/react'

import * as styles from './css-baseline.styles'

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */

type Props = {
  noBackground?: boolean
  fontScaling?: boolean
}

export const CssBaseline = ({
  noBackground = false,
  fontScaling = false,
}: Props) => (
  <Global
    styles={[
      styles.paramFont(fontScaling),
      (theme) => styles.global(theme, { noBackground }),
    ]}
  />
)
