import type { CSSProperties } from 'react'

type KeyframeDefinition = {
  [percentage: string]: CSSProperties
} & {
  from?: CSSProperties
  to?: CSSProperties
}

export const createAnimation = <T extends string>(
  name: T,
  definition: KeyframeDefinition
) => {
  const animationDef = `@keyframes ${name} {
    ${createCssDefinitionFromKeyframeBlock(definition)}
  }`

  return {
    name,
    definition: animationDef,
  } as const
}

const createCssDefinitionFromKeyframeBlock = (definition: KeyframeDefinition) =>
  Object.entries(definition)
    .map(([percentage, cssDefinition]) => {
      const declarationList = stringifyCssDeclarationList(cssDefinition)
      const keyframe = `${percentage} { ${declarationList} }`

      return keyframe
    })
    .join('\n\t\t')

const stringifyCssDeclarationList = (definition: CSSProperties) =>
  Object.entries(definition)
    .map(([key, value]) => `${key}: ${value};`)
    .join('')

/**
 * mixin to generate MSEdge specific `@supports` or `@media` for `IE`
 *
 * @example
 * ```ts
 * const root = css`
 *   // this will be used for IE and Edge
 *   width: 100px;
 *
 *  // this will be used only in IE >=10
 *   ${supports('ie')} {
 *     width: 300px;
 *   }
 *
 *   // this will be used for evergreen browsers ( not IE, not Edge)
 *   ${supports.not('edge')} {
 *     width: 200px;
 *   }
 * `
 * ```
 */
export const supports = (() => {
  function createSupports(negate = false) {
    function generateQuery(browser: 'ie' | 'edge' | unknown) {
      const negatePrefix = negate ? 'not' : ''

      if (browser === 'ie') {
        /* IE10+ */
        return format(
          `@media ${negatePrefix} all and (-ms-high-contrast: none), (-ms-high-contrast: active)`
        )
      }

      /* Edge 12+ */
      if (browser === 'edge') {
        return format(`@supports ${negatePrefix} (-ms-user-select: none)`)
      }

      throw new Error(`Unsupported browser query -> ${browser}`)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const noop: typeof generateQuery = (() => {}) as any
    generateQuery.not = noop

    return generateQuery
  }

  function format(value: string) {
    return value.replace(/\s+/g, ' ')
  }

  const supports = createSupports()
  supports.not = createSupports(true)

  return supports
})()
