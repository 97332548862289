import { Typography } from '@twisto/components/atoms/typography'
import { gitRevision, isProd } from '@twisto/environment'

import * as styles from './version.styles'

type Props = {
  onClick: (() => void) | undefined
  className?: string
}

const hashLength = 8

export const Version = ({ className, onClick }: Props) => {
  const version = `v.${gitRevision?.substring(0, hashLength)}`

  return (
    <Typography
      className={className}
      color="textSecondary"
      css={[styles.root, isProd && styles.showOnHoverOnly]}
      variant="b4"
      onClick={onClick}
    >
      {version}
    </Typography>
  )
}
