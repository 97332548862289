import { Typography } from '@twisto/components/atoms/typography'

import * as styles from './bubble.styles'
import { THUMB_WIDTH } from './slider.styles'

type Props = {
  value: number
  min: number
  max: number
  isVisible: boolean
  htmlFor?: string
}

export const Bubble = ({ value, min, max, isVisible, htmlFor }: Props) => {
  const sliderPosition = ((value - min) * 100) / (max - min)
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const bubbleOffset = THUMB_WIDTH / 2 - sliderPosition * (THUMB_WIDTH / 100)

  return (
    <output
      css={[styles.container, isVisible && styles.visible]}
      data-testid="slider-bubble"
      htmlFor={htmlFor}
      style={{
        transform: `translateX(calc(${sliderPosition}% + (${bubbleOffset}px)))`,
      }}
    >
      <div css={styles.bubble}>
        <Typography
          color="primary"
          component="span"
          css={styles.text}
          fontWeight="medium"
          variant="b2"
        >
          {value}
        </Typography>
        <svg css={styles.arrow} height="4" viewBox="0 0 12 4" width="12">
          <path d="M12 0L3.49691e-07 -1.04907e-06L5.4453 3.6302C5.7812 3.85413 6.2188 3.85413 6.5547 3.6302L12 0Z" />
        </svg>
      </div>
    </output>
  )
}
