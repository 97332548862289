/* eslint-disable @typescript-eslint/no-magic-numbers */
import { palette as paramDarkPalette } from './param-dark-palette'
import { palette as paramPalette } from './param-palette'
import { shadows } from './shadows'

export type PaletteColor = {
  light: string
  main: string
  dark: string
  contrastText: string
}

export type ActionColor = {
  main: string
  dark: string
  contrastText: string
  gradient?: string | null
  text?: string
}

export type NeutralPaletteColor = {
  100: string
  200: string
  300: string
  600: string
  700: string
  800: string
  900: string
}

export type BrandPaletteColor = {
  100: string
  200: string
  400: string
  500: string
}

export type ActionPaletteColor = {
  '000': string
  400: string
  500: string
  gradient?: string
}

export type BackgroundPaletteColor = {
  primary: string
  secondary: string
}

export type OtherPaletteColor = {
  info500: string
  white: string
  overlay: string
  overlayDarker: string
  overlayWhite12: string
  overlayTextBg: string
  overlayNeutral: string
}

export type StatePaletteColor = {
  100: string
  200: string
  500: string
}

export type AdditionalPaletteColor = {
  purple: {
    100: string
    200: string
    500: string
    700: string
  }
  section: string
  tableBackground: string
}

export type NewThemePalette = {
  neutral: NeutralPaletteColor
  brand: BrandPaletteColor
  action: ActionPaletteColor
  background: BackgroundPaletteColor
  other: OtherPaletteColor
  success: StatePaletteColor
  warning: StatePaletteColor
  error: StatePaletteColor
  additionalColor: AdditionalPaletteColor
}

export type ThemeModes = 'light' | 'dark'

export const themes = {
  param: {
    themeName: 'param',
    palette: paramPalette,
    mode: 'light',
    shadows,
  },
} as const

export const getTheme = (themeName: 'param', mode?: ThemeModes) => {
  const theme = themes[themeName]
  const palette = theme.palette
  const requiredMode = mode ?? 'light'
  const darkPalettes = {
    param: paramDarkPalette,
  }

  return {
    ...theme,
    mode: requiredMode,
    palette: requiredMode === 'dark' ? darkPalettes[themeName] : palette,
  }
}
