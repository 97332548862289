export const zIndex = {
  drawer: 1000,
  appBar: 1100,
  loader: 1300,
  modal: 1400,
  paymentModal: 1425,
  deferralSubmodal: 1440,
  timeoutModal: 1450,
  tooltip: 1475,
  errorModal: 1500,
  modalLoader: 1600,
  toast: 2147483010, // 10 more over the z-index of the Zendesk (2147483000)
} as const
