import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import type { Size } from './headline'

/**
 * Context
 */

type SizeValue = Size | undefined

const Context = createContext<SizeValue>(undefined)

/**
 * Provider
 */

type Props = {
  children: ReactNode
  size: Size
}

export const SizeProvider = ({ children, size }: Props) => (
  <Context.Provider value={size}>{children}</Context.Provider>
)

/**
 * Hook
 */

export const useSize = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useSize must be used within a SizeProvider')
  }

  return context
}
