import type { HTMLAttributes, ReactElement } from 'react'

import type { TypographyProps } from '@twisto/components/atoms/typography'
import { Typography } from '@twisto/components/atoms/typography'

type Props = {
  children: ReactElement | string
} & Omit<HTMLAttributes<HTMLElement>, 'color'> &
  TypographyProps

export const ModalTitle = ({ children, ...other }: Props) => (
  <Typography gutterBottom data-testid="modal-title" variant="h2" {...other}>
    {children}
  </Typography>
)
