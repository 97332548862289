import { css } from '@emotion/react'

import { breakpoint, size } from '@twisto/styles'

export const text = css`
  margin-top: ${size[20]};

  ${breakpoint.down('sm')} {
    margin-top: ${size[16]};
  }

  &:first-of-type {
    margin-top: ${size[8]};

    ${breakpoint.down('sm')} {
      margin-top: ${size[4]};
    }
  }
`
