import { useEffect, useRef } from 'react'

import { useAtom } from 'jotai'

import { configVersionAtom } from '@twisto/base'
import { isDev, isTestServer } from '@twisto/environment'
import { useOpen } from '@twisto/hooks'

import type { ConfigEditor } from './config-editor'
import { getStoredConfig } from './get-stored-config'

export const useConfigEditor = () => {
  const { isOpen, handleOpen, handleClose } = useOpen()

  const configModalRef = useRef<typeof ConfigEditor>()

  const [, setConfigVersion] = useAtom(configVersionAtom)
  const reloadConfig = () => setConfigVersion((version) => version + 1)

  useEffect(() => {
    if (!isDev && !isTestServer) {
      return
    }

    import('./config-editor').then(({ ConfigEditor }) => {
      configModalRef.current = ConfigEditor
    })
  }, [])

  if (!isDev && !isTestServer) {
    return {
      openConfigEditor: undefined,
      ConfigEditor: undefined,
    }
  }

  const CurrentFlagsEditor = configModalRef.current
  const FinalConfigModal = CurrentFlagsEditor
    ? () => (
        <CurrentFlagsEditor
          opened={isOpen}
          reloadConfig={reloadConfig}
          onClose={handleClose}
        />
      )
    : undefined

  return {
    openConfigEditor: handleOpen,
    ConfigEditor: FinalConfigModal,
    configEditorOverride: {
      language: getStoredConfig('environment')?.language as
        | GLOBALS.Language
        | undefined,
      region: getStoredConfig('environment')?.region as
        | GLOBALS.Region
        | undefined,
      featureFlags: getStoredConfig('featureFlags') as
        | Record<string, boolean | undefined>
        | undefined,
    },
  }
}
