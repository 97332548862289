import type { HTMLAttributes, ReactNode } from 'react'

type Props = {
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const ModalContent = ({ children, ...other }: Props) => (
  <div data-testid="modal-content" {...other}>
    {children}
  </div>
)
