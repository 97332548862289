import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentProps,
  MouseEvent,
  ReactNode,
} from 'react'
import { forwardRef, useState } from 'react'

import { isNil } from 'ramda'

import { Link } from '@twisto/components/atoms/link'
import { isPromise } from '@twisto/utils'

type BaseButtonProps = {
  children?: ReactNode
  className?: string
  replace?: boolean
  to?: string
  href?: string
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
  anchorProps?: Omit<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    'target' | 'color'
  >
  linkProps?: Omit<ComponentProps<typeof Link>, 'to' | 'href' | 'children'>
  onClick?: (
    e: MouseEvent<HTMLAnchorElement & HTMLButtonElement>
  ) => unknown | Promise<unknown>
} & (
  | {
      href: string
      onClick?: (e: MouseEvent<HTMLAnchorElement>) => unknown | Promise<unknown>
    }
  | {
      to: string
      onClick?: (e: MouseEvent<HTMLAnchorElement>) => unknown | Promise<unknown>
    }
  | {
      onClick?: (e: MouseEvent<HTMLButtonElement>) => unknown | Promise<unknown>
    }
) &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      type = 'button',
      disabled = false,
      href,
      target,
      to,
      replace,
      className,
      anchorProps,
      onClick,
      linkProps,
      ...buttonProps
    },
    ref
  ) => {
    const [onClickInProgress, setOnClickInProgress] = useState(false)

    const awaitedOnClick = onClick
      ? async (
          e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>
        ) => {
          // the types are checked on component level
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fnResult = onClick(e as any)

          // find out if function is awaitable
          if (isPromise(fnResult)) {
            setOnClickInProgress(true)
            const resolvedResult = await fnResult
            setOnClickInProgress(false)

            return resolvedResult
          }

          return fnResult
        }
      : undefined

    if (!isNil(to)) {
      return (
        <Link
          className={className}
          replace={replace}
          role="button"
          to={to}
          onClick={awaitedOnClick}
          {...linkProps}
          {...(buttonProps as ComponentProps<typeof Link>)}
        >
          {children}
        </Link>
      )
    }

    if (!isNil(href)) {
      return (
        <Link
          className={className}
          href={href}
          role="button"
          target={target}
          {...anchorProps}
          {...(buttonProps as ComponentProps<typeof Link>)}
          onClick={awaitedOnClick}
        >
          {children}
        </Link>
      )
    }

    return (
      <button
        ref={ref}
        className={className}
        disabled={disabled || onClickInProgress}
        type={type}
        onClick={awaitedOnClick}
        {...buttonProps}
      >
        {children}
      </button>
    )
  }
)

BaseButton.displayName = 'BaseButton'
