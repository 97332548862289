import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { ToastGroup } from './toast-group'
import type { Message, ShowToastArgs, Variant } from './toast.context'
import { Context } from './toast.context'

export type Toast = {
  key: number
  message: Message
  variant: Variant
}

type Props = {
  children: ReactNode
}

export const ToastProvider = ({ children }: Props) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const showToast = useCallback(({ message, variant }: ShowToastArgs) => {
    setToasts((state) => [
      ...state,
      {
        key: Date.now(),
        message,
        variant,
      },
    ])
  }, [])

  return (
    <Context.Provider value={{ showToast }}>
      {children}
      <ToastGroup setToasts={setToasts} toasts={toasts} />
    </Context.Provider>
  )
}
