import type { SerializedStyles, Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, breakpoint, size } from '@twisto/styles'

import type { Variant } from './toast.context'

export const box = css`
  display: grid;
  position: relative;
  align-items: center;
  border-top-left-radius: ${borderRadius.md};
  border-bottom-left-radius: ${borderRadius.md};
  grid-template-columns: ${size[24]} auto;
  column-gap: ${size[8]};
  padding: ${size[12]};

  ${breakpoint.up('sm')} {
    grid-template-columns: ${size[32]} auto;
    column-gap: ${size[16]};
    padding: ${size[24]};
  }
`

export const variants: Record<Variant, (theme: Theme) => SerializedStyles> = {
  success: (theme: Theme) => css`
    background: ${theme.palette.success[200]};
    color: ${theme.palette.brand[500]};
  `,
  warning: (theme: Theme) => css`
    background: ${theme.palette.warning[200]};
  `,
  info: (theme: Theme) => css`
    background: ${theme.palette.neutral[200]};
  `,
  error: (theme: Theme) => css`
    background: ${theme.palette.error[200]};
  `,
}

export const button = css`
  display: flex;
  position: absolute;
  outline: 0;
  border: none;
  background: transparent;
  top: 0;
  right: 0;
  padding: ${size[8]};
  cursor: pointer;
  pointer-events: all;
`
