import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const container = css`
  display: grid;
  grid-auto-flow: row;
  position: fixed;
  z-index: ${styles.zIndex.toast};
  width: 100%;
  max-width: ${styles.width.container.tiny};
  bottom: ${styles.size[8]};
  right: 0;
  pointer-events: none;
  padding-left: ${styles.size[8]};

  ${styles.breakpoint.up('sm')} {
    bottom: ${styles.size[88]};
    padding-left: 0;
  }
`

export const wrapper = (theme: Theme) => css`
  width: 100%;
  transform-origin: center center;
  transform-style: preserve-3d;
  background: ${theme.palette.background.primary};
  border-top-left-radius: ${styles.borderRadius.md};
  border-bottom-left-radius: ${styles.borderRadius.md};
  margin: 0 0 ${styles.size[8]} 0;
`
