import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import {
  borderRadius,
  breakpoint,
  duration,
  easing,
  size,
} from '@twisto/styles'

export type Sizes = keyof typeof sizes
export type Colors = keyof ReturnType<typeof colors>
export type Variants = keyof ReturnType<typeof variants>

export const root = (theme: Theme) => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  border-radius: ${borderRadius.sm};
  cursor: pointer;
  user-select: none;
  transition: background ${duration.short}ms ${easing.easeIn};

  &[disabled] {
    color: ${theme.palette.neutral[600]};
    background: ${theme.palette.neutral[100]};
    pointer-events: none;
  }
`

export const fullWidth = css`
  width: 100%;
`

export const sizes = {
  small: css`
    padding: 0 ${size[16]};
    min-height: ${size[32]};
  `,
  medium: css`
    padding: 0 ${size[24]};
    min-height: ${size[48]};
  `,
  large: css`
    // mobile version doesn't have a large variant, so we reapply medium variant styles
    padding: 0 ${size[24]};
    min-height: ${size[48]};

    // keep breakpoint in sync with breakpoint of font-size in css baseline
    ${breakpoint.up('md')} {
      padding: 0 ${size[32]};
      min-height: ${size[64]};
    }
  `,
}

export const colors = (theme: Theme) => ({
  primary: css`
    color: ${theme.palette.action['000']};
    background: ${theme.palette.action[500]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.action[400],
      focusOutlineColor: theme.palette.brand[200],
    })}
  `,
  secondary: css`
    color: ${theme.palette.additionalColor.purple[500]};
    background: ${theme.palette.additionalColor.purple[100]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.additionalColor.purple[200],
      focusOutlineColor: theme.palette.additionalColor.purple[200],
    })}
  `,
  negative: css`
    color: ${theme.palette.error[500]};
    background: ${theme.palette.error[100]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.error[200],
      focusOutlineColor: theme.palette.error[200],
    })}
  `,
  ghost: css`
    color: ${theme.palette.additionalColor.purple[500]};
    background: transparent;

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.additionalColor.purple[100],
      focusOutlineColor: theme.palette.additionalColor.purple[200],
    })}
  `,
})

export const variants = (theme: Theme) => ({
  default: css`
    background: ${theme.palette.additionalColor.purple[700]};
    color: ${theme.palette.action['000']};
    outline: 1px solid ${theme.palette.additionalColor.purple[700]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.action[500],
      hoverOutlineColor: theme.palette.action[500],
      focusOutlineColor: theme.palette.action[500],
    })}
  `,
  outlined: css`
    background: transparent;
    color: ${theme.palette.additionalColor.purple[700]};
    outline: 1px solid ${theme.palette.additionalColor.purple[700]};

    ${colorPseudoClasses({
      hoverColor: theme.palette.action[500],
      hoverOutlineColor: theme.palette.action[500],
    })}
  `,
  inverted: css`
    background: ${theme.palette.background.primary};
    color: ${theme.palette.additionalColor.purple[700]};
    outline: 1px solid ${theme.palette.additionalColor.purple[700]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.action[500],
      hoverColor: theme.palette.background.primary,
      hoverOutlineColor: theme.palette.action[500],
      focusOutlineColor: theme.palette.action[500],
    })}
  `,
  outlinedInverted: css`
    background: transparent;
    color: ${theme.palette.background.primary};
    outline: 1px solid ${theme.palette.background.primary};

    ${colorPseudoClasses({
      hoverColor: theme.palette.action[500],
      hoverOutlineColor: theme.palette.action[500],
    })}
  `,
})

const colorPseudoClasses = ({
  hoverBackgroundColor,
  hoverOutlineColor,
  hoverColor,
  focusOutlineColor,
}: {
  hoverBackgroundColor?: string
  focusOutlineColor?: string
  hoverColor?: string
  hoverOutlineColor?: string
}) => css`
  &:hover {
    background: ${hoverBackgroundColor};
    transition: background ${duration.short}ms ${easing.easeOut};
    color: ${hoverColor};
    outline-color: ${hoverOutlineColor};
  }

  &:focus-visible {
    outline: 2px solid ${focusOutlineColor};
    outline-offset: 2px;
    transition: background ${duration.short}ms ${easing.easeOut};
  }
`
