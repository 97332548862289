import type { CSSProperties, InputHTMLAttributes, ReactNode } from 'react'
import { useState } from 'react'

import { clamp } from 'ramda'
import type { UseFormReturn } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import { Typography } from '@twisto/components/atoms/typography'
import { getPercentageBetween } from '@twisto/utils'

import { Bubble } from './bubble'
import * as styles from './slider.styles'

type Props = {
  min: number
  max: number
  name: string
  step?: number
  showLabel?: boolean
  showValue?: boolean
  className?: string
  labelFormatter?: (value: number) => ReactNode
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'pattern'>

export const SLIDER_ID = 'slider'

export const Slider = ({
  value,
  min,
  max,
  step,
  onChange,
  showLabel = false,
  showValue = false,
  labelFormatter,
  name,
  className,
  ...other
}: Props) => {
  const { register, watch } = (useFormContext() as UseFormReturn | null) || {}

  const formProps = register?.(name, {
    ...other,
    onChange,
  })

  const watchedValue = watch && watch(name)
  const clampedValue = clamp(min, max, Number(value ?? watchedValue))

  const [isBubbleVisible, setIsBubbleVisible] = useState(false)
  const handleShowBubble = () => setIsBubbleVisible(true)
  const handleHideBubble = () => setIsBubbleVisible(false)

  const getHorizontalBackgroundSize = (
    value: number,
    min: number,
    max: number
  ) =>
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    `${getPercentageBetween(0, value, min, max).toFixed(2)}%`

  const style: CSSProperties = {
    backgroundSize: `${getHorizontalBackgroundSize(
      clampedValue - min,
      min - min,
      Math.ceil(max) - min
    )} 100%`,
    backgroundPosition: `0 100%`,
  }

  return (
    <div className={className} css={styles.wrapper} data-testid="slider">
      {showValue && (
        <Bubble
          htmlFor={SLIDER_ID}
          isVisible={isBubbleVisible}
          max={max}
          min={min}
          value={clampedValue}
        />
      )}
      <input
        css={styles.slider}
        id={SLIDER_ID}
        max={Math.ceil(max)}
        min={min}
        name={name}
        step={step}
        style={style}
        type="range"
        value={clampedValue}
        onChange={onChange}
        onMouseEnter={handleShowBubble}
        onMouseLeave={handleHideBubble}
        onTouchEnd={handleHideBubble}
        onTouchStart={handleShowBubble}
        {...other}
        {...formProps}
      />
      {showLabel && (
        <div css={styles.footer}>
          <Typography color="textTertiary" variant="b4">
            {labelFormatter ? labelFormatter(min) : min}
          </Typography>
          <Typography color="textTertiary" variant="b4">
            {labelFormatter ? labelFormatter(max) : max}
          </Typography>
        </div>
      )}
    </div>
  )
}
