import type { ComponentProps } from 'react'

import type { Theme } from '@emotion/react'

import { BaseLink } from './base-link'
import * as styles from './link.styles'

type Props = {
  color?: keyof typeof styles.colors
  underline?: keyof typeof styles.underlines
  hover?: keyof typeof styles.hovers
  variant?: keyof typeof styles.variants
} & ComponentProps<typeof BaseLink>

export const Link = ({
  to,
  children,
  color = 'default',
  underline = 'none',
  replace,
  locationState,
  hover = 'none',
  variant = 'none',
  ...other
}: Props) => {
  const linkCss = (theme: Theme) => [
    styles.root,
    styles.colors[color](theme),
    styles.underlines[underline],
    styles.hovers[hover],
    styles.variants[variant],
  ]

  return (
    <BaseLink
      css={linkCss}
      data-testid="link"
      locationState={locationState}
      replace={replace}
      to={to}
      {...other}
    >
      {children}
    </BaseLink>
  )
}
