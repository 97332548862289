import type { ReactElement, ReactNode } from 'react'

import { Trans } from '@lingui/react'
import { isNil } from 'ramda'

import { TextButton } from '@twisto/components/atoms/button'
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalContentText,
  ModalImage,
  ModalTitle,
} from '@twisto/components/atoms/modal'
import { BigGhostSadIcon } from '@twisto/icons'
import { styleUtils } from '@twisto/styles'

import * as styles from './error-modal.styles'

export type ModalErrorProps = {
  opened?: boolean
  title?: ReactElement | string
  errorText?: string
  perex?: ReactNode
  path?: string
  withoutAnimation?: boolean
  icon?: ReactElement
  toDashboardButton?: string
  reloadButton?: boolean
  link?: {
    to: string
    text: ReactElement
    onClick?: () => void
  }
  customButton?: {
    text: ReactElement
    onClick: () => void
  }
}

const modalTitleId = 'error-alert-dialog-title'

export const ErrorModal = ({
  opened,
  title,
  errorText,
  perex,
  path,
  withoutAnimation,
  icon,
  toDashboardButton,
  link,
  customButton,
  reloadButton = true,
}: ModalErrorProps) => {
  function handleReloadAction() {
    if (!isNil(path)) {
      window.location.href = window.location.href.replace(`/${path}`, '')
    } else {
      window.location.reload()
    }
  }

  const handleDashboardAction = () => {
    window.location.assign(toDashboardButton ?? '/')
  }

  const perexToDisplay =
    perex !== undefined ? perex : <Trans id="error.general.description" />

  const handleCustomButtonClick = customButton?.onClick
  const handleLinkClick = link?.onClick

  return (
    <Modal
      aria-labelledby={modalTitleId}
      css={styles.errorModal}
      data-testid="error-modal"
      opened={opened}
      variant="centered"
      withoutAnimation={withoutAnimation}
    >
      <ModalImage>
        {icon ?? <BigGhostSadIcon color="error" size="96" />}
      </ModalImage>
      <ModalTitle
        align="center"
        css={styleUtils.margin.bottom.lg}
        id={modalTitleId}
      >
        {title ?? <Trans id="error.general.title" />}
      </ModalTitle>
      <ModalContent>
        {errorText !== undefined && (
          <ModalContentText gutterBottom align="center">
            {errorText}
          </ModalContentText>
        )}
        <ModalContentText align="center" color="textSecondary" variant="b2">
          {perexToDisplay}
        </ModalContentText>
      </ModalContent>
      <ModalActions>
        {customButton && (
          <TextButton color="secondary" onClick={handleCustomButtonClick}>
            {customButton.text}
          </TextButton>
        )}
        {link && (
          <TextButton color="secondary" to={link.to} onClick={handleLinkClick}>
            {link.text}
          </TextButton>
        )}
        {reloadButton && (
          <TextButton color="secondary" onClick={handleReloadAction}>
            <Trans id="error.network.action.reload" />
          </TextButton>
        )}
        {!isNil(toDashboardButton) && (
          <TextButton color="secondary" onClick={handleDashboardAction}>
            <Trans id="error.action.toDashboard" />
          </TextButton>
        )}
      </ModalActions>
    </Modal>
  )
}
