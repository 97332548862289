import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

export const root = css`
  text-decoration: none;
  font-weight: 500;
`

export const underlines = {
  none: css`
    text-decoration: none;
  `,
  hover: css`
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
  always: css`
    text-decoration: underline;
  `,
}

export const colors = {
  default: (theme: Theme) => css`
    color: ${theme.palette.additionalColor.purple[500]};
  `,
  main: (theme: Theme) => css`
    color: ${theme.palette.neutral[900]};
  `,
  light: (theme: Theme) => css`
    color: ${theme.palette.neutral[700]};
  `,
}

export const hovers = {
  primary: (theme: Theme) => css`
    &:hover {
      color: ${theme.palette.action[500]};
    }
  `,
  none: () => css``,
}

export const variants = {
  primary: (theme: Theme) => css`
    color: ${theme.palette.additionalColor.purple[700]};
  `,
  secondary: (theme: Theme) => css`
    color: ${theme.palette.neutral[700]};
  `,
  none: () => css``,
}
