import type { SerializedStyles } from '@emotion/react'

import type { breakpointValues } from './breakpoints'
import { breakpoint, keys } from './breakpoints'

export const spacing = {
  none: '0',
  xs: '4px',
  sm: '8px',
  smMd: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '40px',
  xxxl: '64px',
  xxxxl: '80px',
} as const

type Keys = keyof typeof breakpointValues
export type RuleSetMap = { [P in Keys]: SerializedStyles }

const defaultOptions = {
  breakpointKeys: keys,
  breakpointSpacing: spacing,
  breakpointQuery: breakpoint.up('md'),
}

export const createDirectionalPropertyCss = (
  property: 'padding' | 'margin',
  position: 'top' | 'bottom' | 'left' | 'right',
  { breakpointKeys, breakpointSpacing, breakpointQuery } = defaultOptions
) => {
  const result = breakpointKeys.reduce((acc, breakpointKey, idx) => {
    const previousBreakpoint = breakpointKeys[idx - 1]
      ? breakpointKeys[idx - 1]
      : breakpointKeys[0]
    const rule = `${property}-${position}`

    const marginDefinition = `${rule}: ${
      breakpointSpacing[previousBreakpoint as Keys]
    };`
    const mediaQuery =
      previousBreakpoint !== breakpointKey
        ? `${breakpointQuery} { ${rule}: ${
            breakpointSpacing[breakpointKey as Keys]
          }; }`
        : ''

    return {
      ...acc,
      [breakpointKey]: `&& { ${marginDefinition} ${mediaQuery} }`,
    }
  }, {} as RuleSetMap)

  return result
}
