import type { ReactElement, ReactNode, RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

import type { SerializedStyles } from '@emotion/react'
import { useDebounce } from 'use-debounce'

import * as styles from './button-ripple-animation.styles'

const debounceTimer = 1500

type Props = {
  color: string
  children: (props: {
    rippleButtonStyles: SerializedStyles
    ref: RefObject<HTMLButtonElement>
    ripples: ReactNode
  }) => ReactElement
}

export const ButtonRippleAnimation = ({ color, children }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  /* ripplePositions are just emotion styles that we later attach to span elements */
  const [ripplePositions, setRipplePositions] = useState<SerializedStyles[]>([])

  useEffect(() => {
    if (buttonRef.current) {
      const button = buttonRef.current

      const clickHandler = (event: MouseEvent) => {
        // calculate the position and dimensions of the ripple based on the click position and button dimensions
        const rect = button.getBoundingClientRect()
        const left = event.clientX - rect.left
        const top = event.clientY - rect.top
        const height = button.clientHeight
        const width = button.clientWidth
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const radius = Math.max(width, height) / 2

        setRipplePositions((previousPositions) => [
          ...previousPositions,
          styles.ripplePosition(
            top - radius,
            left - radius,
            Math.max(width, height),
            Math.max(width, height)
          ),
        ])
      }

      button.addEventListener('click', clickHandler)

      return () => {
        button.removeEventListener('click', clickHandler)
      }
    }
  }, [buttonRef])

  // use debounce to recognize when the user stops clicking and after the 'debounceTimer' duration remove all ripplePositions
  const [debouncedRipples] = useDebounce(ripplePositions, debounceTimer)
  useEffect(() => {
    if (debouncedRipples.length) {
      setRipplePositions([])
    }
  }, [debouncedRipples.length])

  // map through the ripples and return span elements that will be added to the button component later
  const renderRipples = (
    <>
      {ripplePositions.map((positionStyles, index) => (
        <span
          key={index}
          aria-hidden="true"
          css={[styles.ripple, styles.rippleColor(color), positionStyles]}
        />
      ))}
    </>
  )

  return (
    <>
      {children({
        // pass with render prop styles that should be applied on the animated button
        rippleButtonStyles: styles.buttonStyles,
        // pass with render prop ref that should be applied on the animated button
        ref: buttonRef,
        ripples: renderRipples,
      })}
    </>
  )
}
