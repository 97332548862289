import { match } from 'path-to-regexp'
import { isNil, mapObjIndexed } from 'ramda'
import urljoin from 'url-join'

import {
  hasString,
  isBoolean,
  isFunction,
  isNumber,
  isObject,
  isString,
} from './guards'
import type { AddPrefixToRoute } from './types'

export const isBetween = (value: number, min: number, max: number) =>
  value >= min && value <= max

export const getPercentageBetween = (
  value1: number,
  value2: number,
  min: number,
  max: number
) => {
  if (value1 < min || value2 < min || value1 > max || value2 > max) {
    throw new Error(
      `Values must be within specified range. range low: ${value1}, range high: ${value2}, min: ${min}, max: ${max}`
    )
  }

  return Math.abs((Math.abs(value2 - value1) / (max - min)) * 100)
}

export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

export const debug = (value: unknown) =>
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  JSON.stringify(value, null, 2)

export const isPathname = (path: string) => /^[^:]*$/.test(path)
export const isAbsolutePathname = (path: string) =>
  isPathname(path) && /^\//.test(path)
export const isRelativePathname = (path: string) =>
  isPathname(path) && !isAbsolutePathname(path)

export const joinPaths = (...parts: string[]) =>
  urljoin(...parts).replace('?', '/?')

/**
 * @param {string} currentPath - absolute current path
 * @param {string} toPath - relative next path
 * @return {string} absolute next path
 */
export const resolveRelativePath = (currentPath: string, toPath: string) => {
  if (!isRelativePathname(toPath)) {
    return toPath
  }

  const currentPathname = new URL(currentPath, window.location.origin).pathname
  const result = currentPathname.split('/').filter((segment) => segment !== '')
  const toSegments = toPath.split('/')

  for (const segment of toSegments) {
    if (segment === '' || segment === '.') {
      continue
    }
    if (segment === '..') {
      result.pop()
      continue
    }
    result.push(segment)
  }

  const absolutePath = `/${result.join('/')}`.replace(/\/$/, '')

  return absolutePath
}

type RoutesDefinition<T, Prefix extends string> = {
  [K in keyof T]: T[K] extends { path: string }
    ? AddPrefixToRoute<T[K], Prefix>
    : T[K]
}

export const prependPathToRoutes = <R, Prefix extends string>(
  basepath: Prefix,
  routes: R
) => {
  const prependPath = <T>(value: T): T => {
    if (isObject(value)) {
      return mapObjIndexed(prependPath, value) as T
    }

    if (isString(value)) {
      return joinPaths(basepath, value) as unknown as T
    }

    if (isFunction(value)) {
      return ((...args: unknown[]) =>
        joinPaths(basepath, value(...args))) as unknown as T
    }

    if (isNumber(value) || isBoolean(value) || isNil(value)) {
      return value
    }

    throw new Error('Unexpected value for mapping')
  }

  return prependPath(routes) as RoutesDefinition<R, Prefix>
}

export const appendSearchToRoutes = <R>(search: string, routes: R): R => {
  const appendSearch = <T>(value: T): T => {
    if (isObject(value)) {
      return mapObjIndexed(appendSearch, value) as T
    }

    if (isString(value)) {
      return joinPaths(value, search) as unknown as T
    }

    if (isFunction(value)) {
      return ((...args: unknown[]) =>
        joinPaths(value(...args), search)) as unknown as T
    }

    if (isNumber(value) || isBoolean(value) || isNil(value)) {
      return value
    }

    throw new Error('Unexpected value for mapping')
  }

  return appendSearch(routes)
}

export const getNestedRoutePath = (parentPath: string, childPath: string) => {
  const normalizedParentPath = parentPath.replace('*', '')

  if (!childPath.includes(normalizedParentPath)) {
    throw new Error('Child path has to contain parent path')
  }

  return childPath.replace(normalizedParentPath, '')
}

export const getRedirectUrlFromSearch = () => {
  const search = new URL(
    decodeURIComponent(window.location.href),
    window.location.origin
  ).search

  const searchParams = new URLSearchParams(search)
  const redirectUrl = searchParams.get('redirect')
  let redirectUrlFromParam: string | null = null

  if (redirectUrl !== null) {
    searchParams.delete('redirect')

    redirectUrlFromParam = `${redirectUrl}?${searchParams.toString()}`
  }

  // if we have redirect url form param
  // we want use to redirect here
  if (!isNil(redirectUrlFromParam)) {
    try {
      // Try to create new URL object from given path
      // if redirectUrlFromParam is absolute path `url` will be same as `redirectUrlFromParam`
      const url = new URL(redirectUrlFromParam, window.location.origin)

      if (url.pathname !== '/') {
        return url.pathname + url.search
      }

      return null
    } catch {
      return null
    }
  }

  return null
}

export const createRedirectUrl = (to: string, redirectPathName: string) => {
  const pathname = new URL(to, window.location.origin).pathname
  // replace first "?" with "&" because "?" will be added in redirectUrl
  const search = window.location.search.replace(/^\?/, '&')

  // to prevent "multiple" searches in url eg. `/app/auth/?redirect=/app/signup/?order=1`
  // we encode this redirect url param to to `/app/auth/?redirect=/app/signup/email/%3Forder%3D1`...
  // getRedirectUrlFromSearch() already works with encoded urls.
  const encodedSearchPath = encodeURIComponent(search)
  const redirectUrl = `${pathname}?redirect=${
    redirectPathName + encodedSearchPath
  }`

  return redirectUrl
}

export const matchPath = <
  Args extends Record<string, unknown> = Record<string, unknown>,
>(
  matchPathname: string,
  currentPathname: string
) => {
  const transformedPath = matchPathname
    .replace(/https?:\/\//, '') // remove protocol
    .replace(/\*$/, '(.*)') // transform asterisk to regex
    .replace(/\?.*$/, '') // remove query string
  const currentPathnameWithoutSearch = new URL(
    currentPathname,
    window.location.origin
  ).pathname
  const matchResult = match<Args>(transformedPath)(currentPathnameWithoutSearch)

  return matchResult
}

export const localeIncludes = (
  str: string,
  searchStr: string,
  {
    normalize = false,
    position = 0,
    ...options
  }: {
    normalize?: boolean
    position?: number
    sensitivity?: 'base' | 'accent' | 'case' | 'variant'
  } & Intl.CollatorOptions = {}
) => {
  if (!hasString(str)) {
    return false
  }
  if (!hasString(searchStr)) {
    return true
  }

  // if normalize ignore white spaces and separate accent characters
  const diacriticalMarks = /[\u0300-\u036f]/g
  const normalizedString = normalize
    ? str.replace(diacriticalMarks, '').replace(/ /g, '')
    : str
  const normalizedSearchString = normalize
    ? searchStr.replace(diacriticalMarks, '').replace(/ /g, '')
    : searchStr

  // look at the each possible substring in the normalizedString and compare it with normalizedSeachString
  // by default it ignores accents and case sensitivity, this can be adjusted by setting sensitivity param
  const normalizedSearchStringLength = normalizedSearchString.length
  const lengthDiff = normalizedString.length - normalizedSearchStringLength
  for (let i = position; i <= lengthDiff; i++) {
    if (
      normalizedString
        .substring(i, i + normalizedSearchStringLength)
        .localeCompare(normalizedSearchString, undefined, {
          sensitivity: 'base',
          ...options,
        }) === 0
    ) {
      return true
    }
  }

  return false
}
