import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import { isNil } from 'ramda'

import type { AppConfig } from './config'

const ConfigContext = createContext<null | AppConfig>(null)

export const useAppConfig = () => {
  const context = useContext(ConfigContext)

  if (isNil(context)) {
    throw new Error('useAppConfig must be used within a AppConfigProvider')
  }

  return context
}

type AppConfigProviderProps = {
  children: ReactNode
  config: AppConfig
}
export const AppConfigProvider = ({
  children,
  config,
}: AppConfigProviderProps) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
)

type AppConfigConsumerProps = {
  children: (context: ReturnType<typeof useAppConfig>) => JSX.Element
}
export const AppConfigConsumer = ({ children }: AppConfigConsumerProps) => {
  const context = useAppConfig()

  return children(context)
}
