import type { ComponentType } from 'react'
import { memo } from 'react'

import type { Theme } from '@emotion/react'
import { jsx } from '@emotion/react'

import { hasString } from '@twisto/utils'

import * as styles from './icons.styles'
import type { IconA11yProps, SvgProps } from './types'

export type IconProps = {
  color?: keyof typeof styles.colors
  size?: keyof typeof styles.sizes
} & SvgProps

export function createSvgIconFromGenerated(
  SvgNode: ComponentType<SvgProps>,
  displayName: string
) {
  const Icon = memo(
    ({ titleAccess, color = 'inherit', size = '30', ...other }: IconProps) => {
      const a11yProps = createIconA11yProps({ titleAccess })

      const iconCss = (theme: Theme) => [
        styles.root,
        styles.colors[color](theme),
        styles.sizes[size],
      ]

      return jsx(SvgNode, {
        ...other,
        ...a11yProps,
        css: iconCss,
      })
    }
  )
  Icon.displayName = `${displayName}Icon`

  return Icon
}

export function createIconA11yProps({ titleAccess }: IconA11yProps) {
  return {
    title: titleAccess,
    role: hasString(titleAccess) ? 'img' : 'presentation',
    'aria-hidden': hasString(titleAccess) ? 'false' : 'true',
  } as const
}
