import { css, keyframes } from '@emotion/react'

import { duration } from '@twisto/styles'

// buttonStyles are passed through the children renderProp and should be applied on the animated button
export const buttonStyles = css`
  position: relative;
  overflow: hidden;
`

const rippleAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 100%;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
`

export const ripple = css`
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  animation: ${rippleAnimation} ${duration.longer}ms linear;
  transform: scale(0);
  opacity: 100%;
`

export const rippleColor = (color: string) => css`
  background: ${color};
`

export const ripplePosition = (
  top: number,
  left: number,
  width: number,
  height: number
) => css`
  top: ${top}px;
  left: ${left}px;
  width: ${width}px;
  height: ${height}px;
`
