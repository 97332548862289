import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { useLocation, useNavigate } from '@twisto/hooks'

export type LocationContext = {
  location: ReturnType<typeof useLocation>
  navigate: ReturnType<typeof useNavigate>
}
type Props = {
  children: (props: LocationContext) => ReactNode
}

export const Location = ({ children }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    children({ location, navigate })
  }, [location, navigate, children])

  return null
}
