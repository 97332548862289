import { i18n } from '@lingui/core'

export type TranslationBundle =
  | 'customer'
  | 'psp'
  | 'merchant-portal'
  | 'twisto-js'
  | 'flow'
  | 'homepage'

type Config = {
  language: GLOBALS.Language
  translationBundle?: TranslationBundle
}

const DEFAULT_LANG: Config['language'] = 'en'

export const setupI18n = async ({
  language = DEFAULT_LANG,
  translationBundle = 'customer',
}: Config) => {
  let messages = {}

  const commonMessagesPromise = import(
    /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/common-messages`
  )

  if (translationBundle === 'customer') {
    const customerMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/customer-messages`
    )
    const flowMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/flow-messages`
    )
    const [customer, flow, common] = await Promise.all([
      customerMessagesPromise,
      flowMessagesPromise,
      commonMessagesPromise,
    ])
    messages = {
      ...customer.messages,
      ...flow.messages,
      ...common.messages,
    }
  }

  if (translationBundle === 'psp') {
    const pspMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/psp-messages`
    )
    const [psp, common] = await Promise.all([
      pspMessagesPromise,
      commonMessagesPromise,
    ])
    messages = { ...psp.messages, ...common.messages }
  }

  if (translationBundle === 'merchant-portal') {
    const merchantPortalMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/merchant-portal-messages`
    )
    const [merchantPortal, common] = await Promise.all([
      merchantPortalMessagesPromise,
      commonMessagesPromise,
    ])
    messages = { ...merchantPortal.messages, ...common.messages }
  }

  if (translationBundle === 'twisto-js') {
    const twistoJSMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/twistojs-messages`
    )
    const [twistoJS, common] = await Promise.all([
      twistoJSMessagesPromise,
      commonMessagesPromise,
    ])
    messages = { ...twistoJS.messages, ...common.messages }
  }

  if (translationBundle === 'flow') {
    const flowMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/flow-messages`
    )
    const [flow, common] = await Promise.all([
      flowMessagesPromise,
      commonMessagesPromise,
    ])
    messages = { ...flow.messages, ...common.messages }
  }

  if (translationBundle === 'homepage') {
    const homepageMessagesPromise = import(
      /* webpackChunkName: "[request]" */ `../../../public/locale/${language}/homepage-messages`
    )
    const [homepage, common] = await Promise.all([
      homepageMessagesPromise,
      commonMessagesPromise,
    ])
    messages = { ...homepage.messages, ...common.messages }
  }

  i18n.load(language, messages)
  i18n.activate(language)
}
