// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addGtmData = (data: Record<string, any>) => {
  // consumed by google tag manager
  window.GTM = {
    ...window.GTM,
    ...data,
  }

  // trigger custom event to notify about GTM data change
  pushToDataLayer({ event: 'GTMDataChange' })
}

type GtmEvent = {
  event: string
  [eventData: string]: string | number
}

export const pushToDataLayer = (event: GtmEvent) =>
  window.dataLayer?.push(event)
