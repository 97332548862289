import type { ReactNode } from 'react'

import { BrowserRouter } from 'react-router-dom'

type Props = {
  children: ReactNode
  active: boolean
}

export const BrowserRouterWrapper = ({ children, active }: Props) =>
  active ? <BrowserRouter>{children}</BrowserRouter> : <>{children}</>
