import type { ReactNode } from 'react'

import * as styles from './modal-actions.styles'

type Props = {
  children: ReactNode
}

export const ModalActions = ({ children }: Props) => (
  <div css={styles.elementActions} data-testid="modal-actions">
    {children}
  </div>
)
