import type { CSSProperties } from 'react'

import { useTheme } from '@emotion/react'

import { gradients } from '@twisto/icons'

import { SvgIcon } from '../icons'

const styles: CSSProperties = {
  width: 0,
  height: 0,
  position: 'absolute',
}

export const SvgGradient = () => {
  const { themeName, palette } = useTheme()

  return (
    <SvgIcon style={styles}>
      <defs>
        {themeName === 'twisto' && (
          <>
            <linearGradient
              id={gradients.primary}
              x1="0%"
              x2="100%"
              y1="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#90E040" />
              <stop offset="100%" stopColor="#2ECD5C" />
            </linearGradient>
            <linearGradient
              id={gradients.secondary}
              x1="100%"
              x2="0%"
              y1="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#795CD3" />
              <stop offset="100%" stopColor="#6D2AB5" />
            </linearGradient>
            <linearGradient
              id={gradients.actionPrimary}
              x1="0%"
              x2="100%"
              y1="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#90E040" />
              <stop offset="100%" stopColor="#2ECD5C" />
            </linearGradient>
          </>
        )}
        {themeName === 'param' && (
          <>
            <linearGradient
              id={gradients.primary}
              x1="0%"
              x2="100%"
              y1="100%"
              y2="0%"
            >
              <stop stopColor={palette.brand[500]} />
            </linearGradient>
            <linearGradient
              id={gradients.secondary}
              x1="100%"
              x2="0%"
              y1="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#795CD3" />
              <stop offset="100%" stopColor="#6D2AB5" />
            </linearGradient>
            <linearGradient
              id={gradients.actionPrimary}
              x1="0%"
              x2="100%"
              y1="100%"
              y2="0%"
            >
              <stop stopColor={palette.action[500]} />
            </linearGradient>
          </>
        )}
        {/* this is default color: success, warning, error */}
        <linearGradient
          id={gradients.success}
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#90E040" />
          <stop offset="100%" stopColor="#2ECD5C" />
        </linearGradient>
        <linearGradient
          id={gradients.warning}
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#FDCA3D" />
          <stop offset="100%" stopColor="#FFA142" />
        </linearGradient>
        <linearGradient
          id={gradients.error}
          x1="100%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#F6586A" />
          <stop offset="100%" stopColor="#EC3F3F" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
