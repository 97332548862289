import { gradients } from '@twisto/icons'

import { SvgIcon } from '../../icons'

import * as styles from './twisto-loader.styles'

export const TwistoLoader = () => (
  <div css={styles.imageWrapper}>
    <SvgIcon css={styles.image} viewBox="0 0 70 70">
      <path
        d="M35,2.16004992e-12 C15.6700338,2.16004992e-12 -3.41060513e-13,15.6700338 -3.41060513e-13,35 L35,35 L35,2.16004992e-12 Z"
        fill={`url(#${gradients.primary})`}
      />
      <circle
        cx="35"
        cy="35"
        fill="none"
        r="24"
        stroke={`url(#${gradients.secondary})`}
        strokeWidth={2}
      />
    </SvgIcon>
  </div>
)
