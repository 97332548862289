import type { SerializedStyles } from '@emotion/react'
import { css } from '@emotion/react'

import type { Align } from './headline'

export const container = css`
  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
`

export const align: Record<Align, SerializedStyles> = {
  start: css`
    justify-items: start;
    text-align: start;
  `,
  center: css`
    justify-items: center;
    text-align: center;
  `,
}
