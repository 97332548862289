import type { CleaveOptions } from 'cleave.js/options'
import type { NumericFormatProps } from 'react-number-format'

export const blocks = {
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
}

export const delimiters = {
  comma: ',',
  dash: '-',
  dot: '.',
  dotSpace: '. ',
  slash: '/',
  space: ' ',
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  unbreakableSpace: String.fromCharCode(160),
}

export const DATE_KEYS = {
  DAY: 'd',
  MONTH: 'm',
  YEAR: 'Y',
}

export type CleaveDateOptions = {
  date: true
  datePattern: [string, string, string]
  delimiter: string
}

export type CleaveMoneyOptions = {
  numeral: true
  numeralThousandsGroupStyle: CleaveOptions['numeralThousandsGroupStyle']
  delimiter: string
}

export const datePresets: Record<GLOBALS.Language, CleaveDateOptions> = {
  cs: {
    date: true,
    datePattern: [DATE_KEYS.DAY, DATE_KEYS.MONTH, DATE_KEYS.YEAR],
    delimiter: delimiters.dotSpace,
  },
  pl: {
    date: true,
    datePattern: [DATE_KEYS.DAY, DATE_KEYS.MONTH, DATE_KEYS.YEAR],
    delimiter: delimiters.dot,
  },
  en: {
    date: true,
    datePattern: [DATE_KEYS.MONTH, DATE_KEYS.DAY, DATE_KEYS.YEAR],
    delimiter: delimiters.slash,
  },
}

export const moneyPresets: Record<GLOBALS.Language, CleaveMoneyOptions> = {
  cs: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    delimiter: delimiters.space,
  },
  pl: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    delimiter: delimiters.space,
  },
  en: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    delimiter: delimiters.comma,
  },
}

export type CurrencyOptions = {
  decimalSeparator: NumericFormatProps['decimalSeparator']
  thousandSeparator: NumericFormatProps['thousandSeparator']
}

export const currencyPresets: Record<GLOBALS.Language, CurrencyOptions> = {
  cs: {
    decimalSeparator: delimiters.comma,
    thousandSeparator: delimiters.space,
  },
  pl: {
    decimalSeparator: delimiters.comma,
    thousandSeparator: undefined,
  },
  en: {
    decimalSeparator: delimiters.dot,
    thousandSeparator: delimiters.comma,
  },
}
