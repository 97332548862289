import { useMemo } from 'react'

import { atom, useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { isEmpty, isNil } from 'ramda'

import { isDev, isTestServer } from '@twisto/environment'

import { envConfig } from '../env-config'
import { regionObjects } from '../region'
import { remoteLanguageAtom } from '../remote-language'

type Config = {
  region?: GLOBALS.Region
  language?: GLOBALS.Language
}

type ConfigEditorOverride = Config & {
  featureFlags?: Record<string, boolean | undefined>
}

export const configVersionAtom = atom(1)

export const setupAppConfig = (
  config?: Config,
  configEditorOverride?: ConfigEditorOverride
) => {
  const baseConfig = {
    region: config?.region ?? envConfig.region,
  }
  const isBrowser = typeof window !== 'undefined'

  // resolve region on localhost (not applied for `cz.twisto.top`)
  if (isDev && isBrowser && /localhost/.test(window.location.host)) {
    baseConfig.region =
      configEditorOverride?.region ?? config?.region ?? baseConfig.region
  }

  const extendedConfig = {
    ...regionObjects[baseConfig.region],
    language:
      config?.language ??
      regionObjects[baseConfig.region].defaultRegionLanguage,
  }

  // resolve featureFlags
  if (isDev || isTestServer) {
    extendedConfig.featureFlags = {
      ...extendedConfig.featureFlags,
      ...configEditorOverride?.featureFlags,
    }
  }

  // resolve language in dev
  if (isDev) {
    const storedLanguage = !isEmpty(configEditorOverride?.language)
      ? configEditorOverride?.language
      : undefined

    extendedConfig.language =
      storedLanguage ?? config?.language ?? extendedConfig.defaultRegionLanguage
  }

  const finalConfig = Object.freeze({
    ...extendedConfig,
    ...baseConfig,
  })

  if (isBrowser) {
    window.LANG = finalConfig.language
  }

  return finalConfig
}

export type AppConfig = ReturnType<typeof setupAppConfig>

export const useSetupConfig = ({
  configEditorOverride,
}: {
  configEditorOverride?: ConfigEditorOverride
}) => {
  const router = useRouter()
  const [configVersion] = useAtom(configVersionAtom)
  const [remoteLanguage] = useAtom(remoteLanguageAtom)

  const domainConfig =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !isNil(router?.locale)
      ? {
          language: (remoteLanguage?.userLanguage.language ??
            remoteLanguage?.userLanguage.default ??
            router.locale.split('-')[0]) as GLOBALS.Language,
        }
      : undefined

  const config = useMemo(
    () => setupAppConfig(domainConfig, configEditorOverride),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configVersion, remoteLanguage]
  )

  return config
}
