import { useTheme } from '@emotion/react'

import { SvgIcon } from '../icons'

import type { LogoProps } from './logo'
import * as styles from './logo.styles'

export const TwistoLogo = ({
  variant = 'icon',
  inverse = false,
  className,
  size = 'medium',
}: LogoProps) => {
  const theme = useTheme()
  const viewBox = variant === 'icon' ? '0 0 60 60' : '0 0 200 60'
  const color = inverse ? theme.palette.other.white : styles.twistoLogoColor

  return (
    <SvgIcon
      className={className}
      css={[styles.root, styles.sizes[size]]}
      viewBox={viewBox}
    >
      {variant === 'full' ? (
        <g fill={color} transform="translate(76.508000, 11.091467)">
          <path d="M10.3176286,23.2272457 L10.3176286,13.5713943 L15.1695714,13.5713943 L15.1695714,8.62571429 L10.3176286,8.62571429 L10.3176286,2.58099429 L4.21357143,2.58099429 L4.21357143,8.62571429 L0.848514286,8.62571429 L0.848514286,13.5713943 L4.21357143,13.5713943 L4.21357143,24.0122743 C4.21357143,28.9579543 7.03082857,31.0775314 11.4914857,31.0775314 C13.1348857,31.0775314 14.6217714,30.76352 15.4043429,30.3710057 L15.4043429,24.9543086 C14.7000286,25.4253257 13.6044286,25.7393371 12.7436,25.7393371 C11.2567143,25.7393371 10.3176286,24.9543086 10.3176286,23.2272457 Z" />
          <polygon points="23.4648286 8.70421714 17.3607714 8.70421714 24.0126286 30.6850171 29.6471429 30.6850171 33.2469714 17.1825257 36.8468 30.6850171 42.4813143 30.6850171 49.1331714 8.70421714 43.2638857 8.70421714 39.3510286 22.6777257 35.5946857 8.70421714 30.9775143 8.70421714 27.3776857 22.6777257" />
          <polygon points="52.4982286 8.70421714 58.6022857 8.70421714 58.6022857 30.76352 52.4982286 30.76352" />
          <circle cx="55.5502571" cy="2.65949714" r="2.50422857" />
          <path d="M75.3493143,17.6535429 L70.8104,16.5545029 C69.4017714,16.1619886 68.8539714,15.6909714 68.8539714,14.7489371 C68.8539714,13.3358857 70.3408571,12.7078629 71.8277429,12.7078629 C73.8624286,12.7078629 74.8797714,13.7284 75.2710571,14.9059429 L81.0620857,14.9059429 C80.2795143,10.7452914 77.2274857,8.07619429 71.7494857,8.07619429 C66.8192857,8.07619429 62.9846857,10.9022971 62.9846857,15.2984571 C62.9846857,19.0665943 65.3324,20.7151543 68.6974571,21.5001829 L73.1581143,22.52072 C74.7232571,22.9132343 75.4275714,23.3842514 75.4275714,24.4047886 C75.4275714,25.8963429 74.1754571,26.6028686 72.2972857,26.6028686 C70.4191143,26.6028686 69.0887429,25.7393371 68.5409429,24.16928 L62.4368857,24.16928 C63.1412,28.7224457 66.8975429,31.2345371 72.2972857,31.2345371 C77.384,31.2345371 81.3751143,28.56544 81.3751143,24.0122743 C81.2968571,20.7151543 79.1839143,18.5170743 75.3493143,17.6535429 Z" />
          <path d="M92.7224,23.2272457 L92.7224,13.5713943 L97.5743429,13.5713943 L97.5743429,8.62571429 L92.7224,8.62571429 L92.7224,2.58099429 L86.6966,2.58099429 L86.6966,8.62571429 L83.3315429,8.62571429 L83.3315429,13.5713943 L86.6966,13.5713943 L86.6966,24.0122743 C86.6966,28.9579543 89.5138571,31.0775314 93.9745143,31.0775314 C95.6179143,31.0775314 97.1048,30.76352 97.8873714,30.3710057 L97.8873714,24.9543086 C97.1830571,25.4253257 96.0874571,25.7393371 95.2266286,25.7393371 C93.6614857,25.7393371 92.7224,24.9543086 92.7224,23.2272457 Z" />
          <path d="M110.956314,8.15469714 C104.539229,8.15469714 99.4525143,13.2573829 99.4525143,19.6946171 C99.4525143,26.1318514 104.539229,31.2345371 110.956314,31.2345371 C117.3734,31.2345371 122.460114,26.1318514 122.460114,19.6946171 C122.460114,13.2573829 117.295143,8.15469714 110.956314,8.15469714 Z M110.956314,25.1898171 C107.904286,25.1898171 105.3218,22.5992229 105.3218,19.5376114 C105.3218,16.476 107.904286,13.8854057 110.956314,13.8854057 C114.008343,13.8854057 116.590829,16.476 116.590829,19.5376114 C116.512571,22.6777257 114.008343,25.1898171 110.956314,25.1898171 Z" />
        </g>
      ) : (
        ''
      )}

      <polygon fill={color} points="20 20 20 60 40 60 40 0 0 0 0 20" />
      <path d="M40,0 C40,11 49,20 60,20 L60,0 L40,0 Z" fill="#009D4E" />
      <path
        d="M40,0 C40,11 49,20 60,20 L60,0 L40,0 Z"
        fill={theme.palette.brand[500]}
      />
    </SvgIcon>
  )
}
