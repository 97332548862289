import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const elementActions = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  ${styles.breakpoint.up('sm')} {
    margin-top: 40px;
  }

  [data-modal-variant='centered'] & {
    justify-content: center;
  }
`
