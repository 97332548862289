/* eslint-disable @typescript-eslint/no-magic-numbers */

// The default font size of the Material Specification.
const defaultFontSize = 14 // px

// what's the font-size on the html element.
// 16px is the default font-size used by browsers.
const defaultHtmlFontSize = 16
const coef = defaultFontSize / 14

export const pxToRem = (size: number) =>
  `${(size / defaultHtmlFontSize) * coef}rem` as const

/**
 * Accepts `rem` as first argument and convert it to `px` size eg. 37.5(rem) -> 600(px)
 *
 * @param remSize
 * @returns `number` in pixels
 */
export const remToPx = (remSize: number) => remSize * defaultHtmlFontSize

export const htmlFontSize = {
  xs: 12,
  md: 14,
  lg: 16,
  xl: 24,
  xxl: 32,
  xxxl: 40,
} as const

// TODO: refactor fontSize object keys
export const fontSize = {
  xxxs: pxToRem(12),
  xxs: pxToRem(14),
  xs: pxToRem(16),
  sm: pxToRem(18),
  smMd: pxToRem(20),
  md: pxToRem(22),
  lg: pxToRem(26),
  xl: pxToRem(28),
  xxl: pxToRem(35),
} as const
