/* eslint-disable @typescript-eslint/no-magic-numbers */
export const shadows = {
  none: 'none',
  // '0 12px 10px -8px rgba(0, 0, 0, 0.1)',
  default: createShadow([{ color: 'rgba(0, 0, 0, 0.1)', px: [0, 12, 10, -8] }]),
  defaultActive: createShadow([
    { color: 'rgba(0, 0, 0, 0.2)', px: [0, 14, 12, -8] },
  ]),
  // '0 12px 10px -8px rgba(46, 205, 92, 0.5)'
  primary: createShadow([
    { color: 'rgba(46, 205, 92, 0.5)', px: [0, 12, 10, -8] },
  ]),
  primaryActive: createShadow([
    { color: 'rgba(46, 205, 92, 0.8)', px: [0, 14, 12, -8] },
  ]),
  // '0 12px 10px -8px rgba(92, 108, 211, 0.5)'
  secondary: createShadow([
    { color: 'rgba(92, 108, 211, 0.5)', px: [0, 12, 10, -8] },
  ]),
  secondaryActive: createShadow([
    { color: 'rgba(92, 108, 211, 0.8)', px: [0, 14, 12, -8] },
  ]),
  // '0px 4px 16px rgba(36, 34, 42, 0.04), 0px 4px 4px rgba(36, 34, 42, 0.04)'
  box: createShadow([
    { color: 'rgba(36, 34, 42, 0.04)', px: [0, 4, 16] },
    {
      color: 'rgba(36, 34, 42, 0.04)',
      px: [0, 4, 4],
    },
  ]),
  boxHover: createShadow([
    { color: 'rgba(130,139,153,0.2)', px: [0, 5, 15, 0] },
  ]),
  whiteShadow: createShadow([
    { color: 'rgba(255, 255, 255, 1)', px: [0, 0, 0, 5] },
  ]),
  // '0 0 24px 0 rgba(42,39,46,0.4)'
  scroll: createShadow([{ color: 'rgba(42,39,46,0.4)', px: [0, 0, 24, 0] }]),
}

type Props = {
  color: string
  px: number[]
}

function createShadow(array: Props[]) {
  return array
    .map(({ px, color }) => {
      const pixels = px.map((item) => `${item}px`).join(' ')

      return [`${pixels} ${color}`].join(', ')
    })
    .join(',')
}
