import { isDev, settings } from '@twisto/environment'
import { format } from '@twisto/form'

import { envConfig } from '../env-config'

import type { CustomerConfig, RegionConfig } from './types'

const { BASE_DOMAIN, STATIC_DOMAIN, HOST } = envConfig
const { DAKTELA_GUI_TOKEN_PL } = settings

export const plDocs = {
  personalDataPolicy: `${STATIC_DOMAIN}/base/docs/pl/twisto-polityka-danych-osobowych.pdf`,
  referralProgramRegulations: `${STATIC_DOMAIN}/base/docs/pl/regulamin-promocji-bonus-z-twisto-iii-edycja.pdf`,
  termsOfService: `${STATIC_DOMAIN}/base/docs/pl/twisto-terms-of-service.pdf`,
  gdprDataProcessing: `${STATIC_DOMAIN}/base/docs/pl/twisto-gdpr-data-processing.pdf`,
  crif: `${STATIC_DOMAIN}/base/docs/pl/twisto-crif.pdf`,
  bik: `${STATIC_DOMAIN}/base/docs/pl/twisto-bik.pdf`,
  kbig: `${STATIC_DOMAIN}/base/docs/pl/twisto-kbig.pdf`,
  endDevicesConsent: `${STATIC_DOMAIN}/base/docs/pl/twisto-end-devices-consent.pdf`,
  marketingAgreement: `${STATIC_DOMAIN}/base/docs/pl/twisto-marketing-agreement.pdf`,
  cardAgreement: `${STATIC_DOMAIN}/base/docs/pl/terms-marqeta.pdf`,
  personalDataProcessing: `${STATIC_DOMAIN}/base/docs/pl/przetwarzanie-danych-osobowych.pdf`,
  pepDeclaration: `${STATIC_DOMAIN}/base/docs/pl/twisto-pep.pdf`,
  planRegulations: `${STATIC_DOMAIN}/base/docs/pl/twisto-regulamin-planow.pdf`,
}

export const plUrls = {
  signup: `${BASE_DOMAIN}/start/onboarding/`,
  verification: `${BASE_DOMAIN}/start/onboarding/`,
  documents: `${BASE_DOMAIN}/klient/dokumenty/`,
  twistoPayShops: `${BASE_DOMAIN}/poznaj/nasze-sklepy/`,
  homepage: `${BASE_DOMAIN}/`,
  eshopLogin: `https://eshop.${HOST.replace('www.', '')}/login/`,
  twistoFeatures: 'https://www.twisto.pl/poznaj/korzysci/',
  billing: `${BASE_DOMAIN}/app/billing/`,
  overview: `${BASE_DOMAIN}/app/overview/`,
  twistoSupport: 'https://twisto.pl/category/faq',
}

export const plCustomerConfig: CustomerConfig = {
  personalId: {
    placeholder: false,
    maxLength: 11,
  },
  externalTwistoExchangeRateUrl: 'https://www.twisto.pl/kurs-wymiany/',
  externalTwistoPayUrl: 'https://www.twisto.pl/poznaj/nasze-sklepy/',
  twistoSupportUrl: plUrls.twistoSupport,
  installmentsTwistoSupportUrl:
    'https://twisto.pl/category/faq/funkcjonalnosci/raty/',
  twistoSnapEmail: '???',
  twistoSnapSupportUrl: '???',
  trialMonthsPeriod: 3,
  maxPlanChargeFee: 30,
}

const config: RegionConfig & typeof envConfig = {
  ...envConfig,
  defaultRegionLanguage: 'pl',
  addressVariant: 'internationalAddress',
  callingCodes: ['+48'],
  currencyCode: 'PLN',
  currencySign: 'zł',
  featureFlags: {
    donations: false,
    twistoPay: true,
    applePay: false,
    pspGateway: true,
    payInThreeDetail: true,
    mobileAppAvailable: true,
    fontScaling: isDev,
  },
  formattedOptions: {
    presets: {
      zipCode: {
        blocks: [format.blocks.two, format.blocks.three],
        delimiter: format.delimiters.dash,
        numericOnly: true,
      },
      personalId: {
        blocks: [format.blocks.eleven],
        numericOnly: true,
      },

      date: format.datePresets,
      money: format.moneyPresets,
    },
    currency: format.currencyPresets,
  },
  brand: 'twisto',
  support: {
    phoneNumber: '+48 22 263 02 10',
    email: 'kontakt@twisto.pl',
    hoursFrom: 8,
    hoursTo: 18,
    days: 'mo-fr',
    daktelaGuiToken: DAKTELA_GUI_TOKEN_PL,
  },
}

export default config
