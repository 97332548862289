import type { BaseHTMLAttributes, ReactNode } from 'react'

import * as styles from './container.styles'

type Props = {
  as?: 'div' | 'article' | 'section' | 'main'
  size?: keyof typeof styles.sizes
  flex?: boolean
  className?: string
  children: ReactNode | ReactNode[]
  noPadding?: boolean
  fullHeight?: boolean
} & BaseHTMLAttributes<HTMLDivElement>

export const Container = ({
  size = 'wide',
  flex = false,
  fullHeight = false,
  className,
  children,
  noPadding,
  as = 'div',
  ...rest
}: Props) => {
  const containerClassName = [
    styles.root,
    styles.sizes[size],
    flex && styles.flex,
    (noPadding ?? false) && styles.noPadding,
    fullHeight && styles.fullHeight,
  ]

  const Element = as

  return (
    <Element
      className={className}
      css={containerClassName}
      data-testid="container"
      {...rest}
    >
      {children}
    </Element>
  )
}
