import { useCallback } from 'react'

import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { isNil } from 'ramda'

import {
  isNumber,
  isRelativePathname,
  isString,
  resolveRelativePath,
} from '@twisto/utils'

import { locationStateAtom } from './location.hook'

type NavigateOptions = {
  replace?: boolean
  state?: unknown
}

export const useNavigate = (): ((
  to: string | number,
  options?: NavigateOptions
) => Promise<void>) => {
  const router = useRouter() // makes sure rerender is triggered on path change
  const [, setLocationState] = useAtom(locationStateAtom)

  return useCallback(
    async (to: string | number, options?: NavigateOptions) => {
      if (isNumber(to)) {
        router.back()
      } else {
        let absoluteTo = to
        if (isString(to) && isRelativePathname(to)) {
          absoluteTo = resolveRelativePath(router.asPath, to)
        }

        const toWithoutBasepath = absoluteTo.replace(
          new RegExp(`^${window.BASEPATH}`),
          ''
        )

        setLocationState(options?.state)

        if (!isNil(options?.replace)) {
          await (router.replace(toWithoutBasepath) as Promise<never>)
        } else {
          await (router.push(toWithoutBasepath) as Promise<never>)
        }
      }
    },
    [router, setLocationState]
  )
}
