import type { HTMLAttributes, ReactNode, RefObject } from 'react'
import { useState } from 'react'

import type { SerializedStyles } from '@emotion/react'
import { DialogContent } from '@reach/dialog'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'

import { ModalOverlay } from '../components'
import { ModalContext } from '../modal-context/modal-context'
import type { ModalA11yProps } from '../types'
import { animationConfig } from '../utils/animation-config'
import { getModalProps } from '../utils/get-modal-props'

import * as styles from './modal.styles'

const AnimatedDialogContent = animated(DialogContent)
type Props = {
  children: ReactNode
  variant?: keyof typeof styles.variants
  verticalAlign?: keyof typeof styles.verticalAlign
  opened?: boolean
  noPadding?: boolean
  autoWidth?: boolean
  withoutOverlay?: boolean
  withoutAnimation?: boolean
  disableFocusLock?: boolean
  onClickOutside?: () => void
  modalBoxRef?: RefObject<HTMLDivElement>
  modalBoxStyles?: SerializedStyles | SerializedStyles[]
  modalWrapperStyles?: SerializedStyles | SerializedStyles[]
  modalOverlayStyles?: SerializedStyles | SerializedStyles[]
  onClose?: () => void
  'data-testid'?: string
} & ModalA11yProps &
  Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'style'>

export const Modal = ({
  children,
  variant = 'default',
  verticalAlign = 'center',
  opened = false,
  noPadding = false,
  autoWidth = false,
  withoutOverlay = false,
  withoutAnimation = false,
  disableFocusLock = false,
  onClickOutside,
  modalBoxRef,
  modalBoxStyles,
  modalWrapperStyles,
  modalOverlayStyles,
  onClose,
  ...rest
}: Props) => {
  const [animationRunning, setAnimationRunning] = useState(false)
  const modalAnimationStyles = useSpring({
    immediate: withoutAnimation,
    from: { transform: !withoutAnimation ? 'scale(0.9)' : 'scale(1.0)' },
    to: {
      transform: opened || withoutAnimation ? 'scale(1.0)' : 'scale(0.9)',
    },
    onStart: () => {
      setAnimationRunning(true)
    },
    onRest: () => {
      setAnimationRunning(false)
      if (!opened && onClose) {
        onClose()
      }
    },
    config: animationConfig,
  })

  const { a11yProps, restProps } = getModalProps(rest)

  return (
    <ModalOverlay
      centered
      css={modalOverlayStyles}
      dangerouslyBypassFocusLock={disableFocusLock}
      hasAnimation={!withoutAnimation}
      hasOverlay={!withoutOverlay}
      isVisible={opened}
      onClickOutside={onClickOutside}
      {...restProps}
    >
      <ModalContext.Provider value={{ isInModal: opened }}>
        <AnimatedDialogContent
          css={[
            styles.wrapper,
            /* for modals with content higher than the viewport, we must disable scrolling during the animation
            to keep the view at the top, while still having the transform-origin of the animation in the center */
            animationRunning && styles.disabledScroll,
            styles.variants[variant],
            styles.verticalAlign[verticalAlign],
            autoWidth && styles.autoWidth,
            modalWrapperStyles,
          ]}
          style={modalAnimationStyles}
          {...a11yProps}
        >
          <div
            ref={modalBoxRef}
            css={[
              styles.content,
              variant === 'wide' && styles.wideContent,
              noPadding && styles.noPadding,
              modalBoxStyles,
            ]}
            data-modal-variant={variant}
            data-testid={rest['data-testid'] ?? 'modal'}
          >
            {children}
          </div>
        </AnimatedDialogContent>
      </ModalContext.Provider>
    </ModalOverlay>
  )
}
