import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const root = css`
  margin-left: auto;
  margin-right: auto;

  padding-left: 20px;
  padding-right: 20px;

  width: 100%;
`

export const sizes = {
  wider: css`
    max-width: ${styles.width.container.wider};
  `,
  wide: css`
    max-width: ${styles.width.container.wide};
  `,
  standard: css`
    max-width: ${styles.width.container.standard};
  `,
  compact: css`
    max-width: ${styles.width.container.compact};
  `,
  small: css`
    max-width: ${styles.width.container.small};
  `,
  tiny: css`
    max-width: ${styles.width.container.tiny};
  `,
}

export const flex = css`
  display: flex;
`

export const noPadding = css`
  padding-left: 0;
  padding-right: 0;
`

export const fullHeight = css`
  height: 100%;
`

export const tinyOnMobile = css`
  ${styles.breakpoint.down('sm')} {
    margin: ${styles.size[0]} auto;
    max-width: ${styles.width.container.tiny};
  }
`
