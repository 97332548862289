import { useEffect, useState } from 'react'

import { isNil } from 'ramda'

import type { BreakpointVariants } from '@twisto/styles'
import { getBreakpointByVariant } from '@twisto/styles'

const stripMedia = (input: string) => input.replace(/@media\s/g, '')

export const useMatchMediaQuery = (
  query: string,
  viewTransitionCallback?: (ready: Promise<unknown>) => void
) => {
  const queryList =
    typeof window !== 'undefined' && typeof window.matchMedia === 'function'
      ? window.matchMedia(query)
      : null

  const [matchesMedia, setMatchesMedia] = useState(queryList?.matches ?? false)

  useEffect(() => {
    if (isNil(queryList)) {
      return
    }

    if (queryList.matches === matchesMedia) {
      return
    }

    setMatchesMedia(queryList.matches)
  }, [matchesMedia, queryList])

  useEffect(() => {
    if (isNil(queryList)) {
      return
    }

    const handleChange = (event: MediaQueryListEvent) => {
      if (isNil(document.startViewTransition)) {
        setMatchesMedia(() => event.matches)

        return
      }

      const { ready } = document.startViewTransition(() => {
        setMatchesMedia(() => event.matches)
      })

      viewTransitionCallback?.(ready)
    }

    queryList.addEventListener('change', handleChange)

    return () => {
      queryList.removeEventListener('change', handleChange)
    }
  }, [queryList, viewTransitionCallback])

  return matchesMedia
}

export const useBreakpoint = (breakpoint: BreakpointVariants) => {
  const mediaQuery = getBreakpointByVariant(breakpoint)
  const query = stripMedia(mediaQuery)

  return useMatchMediaQuery(query)
}
