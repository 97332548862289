import type { ReactNode } from 'react'

import type { Theme } from '@emotion/react'

import * as styles from './layout.styles'

type Props = {
  children: ReactNode
  variant: keyof ReturnType<typeof styles.variants>
  align?: keyof typeof styles.align
  className?: string
  grayBackground?: boolean
  isAppBarFixed?: boolean
  justify?: keyof typeof styles.justify
  smallerPadding?: boolean
  screenWithAppBar?: boolean
  withinCustomerLayout?: boolean
}

export const Layout = ({
  children,
  variant,
  align = 'default',
  className,
  grayBackground = false,
  isAppBarFixed = false,
  justify = 'default',
  smallerPadding = false,
  screenWithAppBar = true,
  withinCustomerLayout = false,
}: Props) => {
  const layoutCss = (theme: Theme) => [
    styles.root,
    styles.variants(theme)[variant],
    variant !== 'screen' && styles.column,
    isAppBarFixed && styles.fixedAppBarMargin,
    smallerPadding && styles.smallerPadding,
    screenWithAppBar && variant === 'screen' && styles.screenHeightWithAppBar,
    withinCustomerLayout && variant === 'screen' && styles.withinCustomerLayout,
    styles.justify[justify],
    styles.align[align],
    grayBackground && styles.grayBackground(theme),
  ]

  return (
    <div className={className} css={layoutCss} data-testid="layout">
      {children}
    </div>
  )
}
