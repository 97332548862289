import type { ReactNode } from 'react'
import { createContext, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'

import { AppConfigProvider, useSetupConfig } from '@twisto/base/app-config'
import { initDayjs } from '@twisto/base/dayjs'
import { addGtmData } from '@twisto/base/gtm'
import type { TranslationBundle } from '@twisto/base/i18n-provider'
import { I18nProvider } from '@twisto/base/i18n-provider'
import { initShims } from '@twisto/base/shim'
import { CssBaseline } from '@twisto/components/atoms/css-baseline'
import { Loader } from '@twisto/components/atoms/loader'
import { Location } from '@twisto/components/atoms/location'
import { SvgGradient } from '@twisto/components/atoms/svg-gradient'
import { ThemeProvider } from '@twisto/components/atoms/theme-provider'
import { ToastProvider } from '@twisto/components/atoms/toast'
import { Version } from '@twisto/components/atoms/version'
import {
  ErrorBoundary,
  ErrorModalProvider,
} from '@twisto/components/molecules/error-boundary'
import { useConfigEditor } from '@twisto/components/organisms/config-editor'

import { BrowserRouterWrapper } from './browser-router-wrapper'

// Context
type BaseAppContextType = { openConfigEditor: (() => void) | undefined }
export const BaseAppContext = createContext<BaseAppContextType>({
  openConfigEditor: undefined,
})

type BaseAppProps = {
  children: ReactNode
  onRouteChange?: () => void
  translationBundle?: TranslationBundle
  noBackground?: boolean
  hasBrowserRouter?: boolean
}

export const BaseApp = ({
  children,
  onRouteChange,
  translationBundle,
  noBackground = false,
  hasBrowserRouter = true,
}: BaseAppProps) => {
  const [shimLoaded, setShimLoaded] = useState(false)

  const { openConfigEditor, ConfigEditor, configEditorOverride } =
    useConfigEditor()
  const config = useSetupConfig({
    configEditorOverride,
  })
  const { language } = config
  useEffect(() => {
    // shim setup
    initShims({ language }).then(() => {
      initDayjs(language)
      setShimLoaded(true)
    })
  }, [language])

  // persist query string to gtm object on mount
  useEffect(() => {
    if (!isEmpty(window.location.search)) {
      addGtmData({ query: window.location.search })
    }
  }, [])

  const handleRouteChange = () => {
    // consumed by google tag manager
    const routeChangeEvent = new CustomEvent('routeChange')
    document.dispatchEvent(routeChangeEvent)

    onRouteChange && onRouteChange()

    return null
  }

  return (
    <ThemeProvider>
      <CssBaseline
        fontScaling={config.featureFlags.fontScaling}
        noBackground={noBackground}
      />
      <SvgGradient />
      <AppConfigProvider config={config}>
        <BaseAppContext.Provider value={{ openConfigEditor }}>
          <BrowserRouterWrapper active={hasBrowserRouter}>
            <I18nProvider
              language={language}
              translationBundle={translationBundle}
            >
              <ErrorModalProvider>
                <Location>{handleRouteChange}</Location>
                {!shimLoaded ? (
                  <Loader />
                ) : (
                  <ErrorBoundary>
                    <ToastProvider>
                      {ConfigEditor && <ConfigEditor />}
                      {children}
                    </ToastProvider>
                  </ErrorBoundary>
                )}
              </ErrorModalProvider>
            </I18nProvider>
          </BrowserRouterWrapper>
        </BaseAppContext.Provider>
      </AppConfigProvider>
      <Version onClick={openConfigEditor} />
    </ThemeProvider>
  )
}
