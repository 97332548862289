import type { ReactNode } from 'react'

import * as styles from './headline-icon.styles'
import { useSize } from './size-context'

type Props = {
  children: ReactNode
  className?: string
}

export const HeadlineIcon = ({ children, className }: Props) => {
  const size = useSize()

  return (
    <div
      className={className}
      css={styles.size[size]}
      data-testid="headline-icon"
    >
      {children}
    </div>
  )
}
