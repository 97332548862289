import { isNumber } from '@twisto/utils'

export const keys = ['xs', 'sm', 'smMd', 'md', 'lg', 'xl']

/* sizes are in rems */
export const breakpointValues = {
  xs: 0,
  sm: 37.5, // 600px
  smMd: 48, // 768px
  md: 60, // 960px
  lg: 80, // 1280px
  xl: 120, // 1920px
} as const

export type Breakpoints = keyof typeof breakpointValues
export type BreakpointVariants = `${Breakpoints}${'Up' | 'Down'}`

type Direction = 'up' | 'down'

const unit = 'rem'
const step = 5
const decimalDivider = 100

const calculateUpperBound = (value: number) => value - step / decimalDivider

const createBreakpoints = <
  T extends { [key: string]: number },
  BP extends keyof T,
>(
  breakpointDefinition: T
) => {
  const validateBpKeys = (bp: unknown, bpMax?: unknown) => {
    const bpValid = keys.includes(bp as string) || isNumber(bp)
    const bpMaxValid =
      bpMax !== undefined ? keys.includes(bpMax as string) : true

    if (!bpValid || !bpMaxValid) {
      throw new Error(
        `[${bp}] is not know breakpoint! Please use one of ${keys} or custom number`
      )
    }
  }

  const up = (key: BP | number) => {
    validateBpKeys(key)
    const value = isNumber(breakpointDefinition[key])
      ? breakpointDefinition[key]
      : key

    return `@media only screen and (min-width:${String(value)}${unit})`
  }

  const down = (key: BP | number) => {
    validateBpKeys(key)

    const endIndex = keys.indexOf(key as string) + 1
    const upperBound = breakpointDefinition[keys[endIndex]]

    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up(keys[0] as BP)
    }

    const FIRST_ARRAY_IDX = 0
    const value =
      isNumber(upperBound) && endIndex > FIRST_ARRAY_IDX
        ? upperBound
        : (key as number)

    return `@media only screen and (max-width:${calculateUpperBound(
      value
    )}${unit})`
  }

  const between = (start: BP, end: BP) => {
    validateBpKeys(start, end)
    const endIndex = keys.indexOf(end as string) + 1

    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up(start)
    }

    return (
      `@media only screen and ` +
      `(min-width:${breakpointDefinition[start]}${unit}) and ` +
      `(max-width:${calculateUpperBound(
        breakpointDefinition[keys[endIndex]]
      )}${unit})`
    )
  }

  const only = (key: BP) => between(key, key)
  const width = (key: BP) => breakpointDefinition[key]

  return {
    up,
    down,
    between,
    only,
    width,
  }
}

export const breakpoint = createBreakpoints(breakpointValues)

export const getBreakpointByVariant = (variant: BreakpointVariants) => {
  const [, breakpointSize, direction] = /^([a-zA-Z]+)(down|up)$/i.exec(
    variant
  ) as unknown as [string, Breakpoints, Direction]

  return breakpoint[direction.toLowerCase() as Direction](breakpointSize)
}
