import { css } from '@emotion/react'

import * as styles from '@twisto/styles'

export const elementImage = css`
  margin-bottom: ${styles.size[16]};

  ${styles.breakpoint.up('sm')} {
    margin-bottom: ${styles.size[32]};
  }
`
