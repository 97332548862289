import type { ComponentProps } from 'react'

import { useTheme } from '@emotion/react'

import { BaseButton } from '../base-button'
import { ButtonRippleAnimation } from '../button-ripple-animation'

import { TextButtonTypography } from './text-button-typography'
import * as styles from './text-button.styles'
import type { Colors, Sizes, Variants } from './text-button.styles'

type Props = {
  color?: Colors
  size?: Sizes
  fullWidth?: boolean
  noTypography?: boolean
  variant?: Variants
} & ComponentProps<typeof BaseButton>

export const TextButton = ({
  color = 'primary',
  size = 'medium',
  fullWidth = false,
  noTypography = false,
  children,
  variant,
  ...buttonProps
}: Props) => {
  const theme = useTheme()

  const rippleColorMap: Record<Colors, string> = {
    primary: theme.palette.other.overlayWhite12,
    secondary: theme.palette.additionalColor.purple[100],
    negative: theme.palette.error[100],
    ghost: theme.palette.additionalColor.purple[100],
  }

  const buttonStyles = [
    styles.root,
    styles.sizes[size],
    styles.colors(theme)[color],
    variant && styles.variants(theme)[variant],
    fullWidth && styles.fullWidth,
  ]

  return (
    <ButtonRippleAnimation color={rippleColorMap[color]}>
      {({ rippleButtonStyles, ref, ripples }) => (
        <BaseButton
          ref={ref}
          css={[buttonStyles, rippleButtonStyles]}
          data-testid="text-button"
          {...buttonProps}
        >
          {ripples}
          <TextButtonTypography noTypography={noTypography} size={size}>
            {children}
          </TextButtonTypography>
        </BaseButton>
      )}
    </ButtonRippleAnimation>
  )
}
