import type { ConfigKey } from './config-editor'

export const getStoredConfig = (configKey: ConfigKey) => {
  let storedConfig = null

  if (process.browser) {
    try {
      storedConfig = localStorage.getItem(configKey)
    } catch (e) {}
  }

  return storedConfig !== null ? JSON.parse(storedConfig) : undefined
}
