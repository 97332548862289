import type { Dispatch, SetStateAction } from 'react'
import { useMemo } from 'react'

import { config } from '@react-spring/core'
import { animated, useTransition } from '@react-spring/web'

import * as styles from './toast-group.styles'
import { ToastMessage } from './toast-message'
import type { Toast } from './toast-provider'

const TIMEOUT = 5000

type Props = {
  toasts: Toast[]
  setToasts: Dispatch<SetStateAction<Toast[]>>
}

export const ToastGroup = ({ toasts, setToasts }: Props) => {
  const cancelMap = useMemo(() => new WeakMap(), [])

  const transitions = useTransition(toasts, {
    from: { transform: 'translateX(100%)', life: '100%' },
    keys: (item) => item.key,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({ transform: 'translateX(0%)' })
      await next({ life: '0%' })
    },
    leave: [{ transform: 'translateX(100%)' }, { height: 0, margin: 0 }],
    onRest: (_, __, item) => {
      setToasts((state) => state.filter((toast) => toast.key !== item.key))
    },
    config: (_, __, phase) => (key) =>
      phase === 'enter' && key === 'life'
        ? { duration: TIMEOUT }
        : config.default,
  })

  return (
    <section css={styles.container} data-testid="toast-group" role="log">
      {transitions(({ life, ...style }, item) => (
        <animated.div css={styles.wrapper} style={style}>
          <ToastMessage
            message={item.message}
            variant={item.variant}
            onClose={() => {
              if (cancelMap.has(item) && life.get() !== '0%') {
                cancelMap.get(item)()
              }
            }}
          />
        </animated.div>
      ))}
    </section>
  )
}
