import dayjs from 'dayjs'
import { getWeekStartByLocale } from 'weekstart'

const monthsInYear = 12
const daysInWeek = 7

export const initDayjs = (language: GLOBALS.Language) => {
  const weekStart = getWeekStartByLocale(language)

  const months = []
  const formatMonth = new Intl.DateTimeFormat(language, { month: 'long' })
  for (let month = 0; month < monthsInYear; month++) {
    const date = dayjs().set('month', month).toDate()
    months.push(formatMonth.format(date))
  }

  const weekdays = []
  const formatWeekday = new Intl.DateTimeFormat(language, { weekday: 'long' })
  for (let day = weekStart; day < weekStart + daysInWeek; day++) {
    const date = dayjs().set('day', day).toDate()
    weekdays.push(formatWeekday.format(date))
  }

  dayjs.locale({
    name: language,
    weekStart,
    formats: {},
    relativeTime: {},
    months,
    weekdays,
  })
}
