import { useState } from 'react'
import type { ReactNode, TouchEvent } from 'react'

import FocusLock from 'react-focus-lock'
import { RemoveScroll } from 'react-remove-scroll'

import { SLIDER_ID } from '@twisto/components/atoms/slider'

import * as styles from './modal-lock.styles'

type Props = {
  children: ReactNode
  isVisible: boolean
}

export const ModalLock = ({ children, isVisible }: Props) => {
  /*  
    Widget focus is the first trigger. After that is triggered a click inside
    the modal so that the second trigged would add focus inside the modal that
    is why we remove autofocus after the first trigger. 
  */
  const [focusLock, setFocusLock] = useState(true)
  const [scrollLock, setScrollLock] = useState(true)

  if (!isVisible) {
    return <>{children}</>
  }

  const handleTouchStart = (event: TouchEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    /*  
      We need to disable scroll lock when the user is interacting with the slider
      on touch devices within the modal.
    */
    if (target.id === SLIDER_ID) {
      setScrollLock(false)
    }
  }

  const handleTouchEnd = () => {
    setScrollLock(true)
  }

  const handleFocus = (element: HTMLElement) => {
    if (element.id === 'webWidget') {
      setFocusLock(false)

      return true
    }

    return !focusLock
  }

  return (
    <div
      css={styles.modalLock}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
    >
      <FocusLock autoFocus={focusLock} whiteList={handleFocus}>
        <RemoveScroll enabled={scrollLock} removeScrollBar={false}>
          {children}
        </RemoveScroll>
      </FocusLock>
    </div>
  )
}
