export const width = {
  container: {
    large: '1430px',
    wider: '1296px',
    wide: '1100px',
    standard: '840px',
    compact: '690px',
    small: '512px',
    tiny: '480px',
    narrowColumn: '416px',
  },
  modal: {
    maxWidth: '1680px',
    compact: '640px',
  },
  navigationSidebar: {
    collapsed: '72px',
    full: '256px',
  },
} as const
