import type { ReactElement } from 'react'

import { Trans } from '@lingui/react'

import { useAppConfig } from '@twisto/base/app-config'
import { Link } from '@twisto/components/atoms/link'
import { isDev } from '@twisto/environment'

import type { ModalErrorProps } from './error-modal'

const i18nKeys = {
  network: {
    title: <Trans id="error.network.title" />,
    perex: <Trans id="error.network.perex" />,
  },
  timeout: {
    title: <Trans id="error.timeout.title" />,
    perex: <Trans id="error.timeout.perex" />,
  },
  server: {
    title: <Trans id="error.server.title" />,
    perex: (email: ReactElement) => (
      <Trans id="error.server.perex" values={{ email }} />
    ),
  },
  request: {
    title: <Trans id="error.request.title" />,
    perex: (email: ReactElement) => (
      <Trans id="error.request.perex" values={{ email }} />
    ),
  },
  nonField: {
    perex: (email: ReactElement) => (
      <Trans id="error.nonFieldError.description" values={{ email }} />
    ),
  },
  withContinue: {
    continueButton: <Trans id="continue" />,
  },
}

export const showDefaultError = (message?: string): ModalErrorProps => ({
  opened: true,
  title: message,
  perex: message !== undefined ? null : undefined, // don't show default perex if message was provided
})

const EmailLink = () => {
  const { support } = useAppConfig()

  return (
    <Link href={`mailto:${support.days}`} underline="hover">
      {support.email}
    </Link>
  )
}

export const showNoConnectionError = (message?: string): ModalErrorProps => ({
  opened: true,
  perex: isDev ? message : i18nKeys.network.perex,
  title: i18nKeys.network.title,
})

export const showTimeoutError = (message?: string): ModalErrorProps => ({
  opened: true,
  perex: isDev ? message : i18nKeys.timeout.perex,
  title: i18nKeys.timeout.title,
})

export const showServerError = (message?: string): ModalErrorProps => ({
  opened: true,
  perex: isDev ? message : i18nKeys.server.perex(<EmailLink />),
  title: i18nKeys.server.title,
})

export const showRequestError = (message?: string): ModalErrorProps => ({
  opened: true,
  perex: isDev ? message : i18nKeys.request.perex(<EmailLink />),
  title: i18nKeys.request.title,
})

export const showErrorWithContinue = (
  close: () => void,
  message?: string,
  perex?: string | null
): ModalErrorProps => ({
  opened: true,
  title: message,
  perex: perex ?? i18nKeys.nonField.perex(<EmailLink />),
  reloadButton: false,
  customButton: {
    onClick: close,
    text: i18nKeys.withContinue.continueButton,
  },
})
