import type { ModalErrorProps } from './error-modal'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppErrorProps = Omit<ModalErrorProps, 'open'> & { payload?: any }

export class AppError extends Error {
  modalErrorProps: AppErrorProps | null = null

  constructor(message: string, modalErrorProps: AppErrorProps = {}) {
    super(message)
    this.modalErrorProps = modalErrorProps
  }
}
