import type { ComponentProps, ReactNode } from 'react'

import { Typography } from '@twisto/components/atoms/typography'

import type { Size } from './headline'
import * as styles from './headline-subtitle.styles'
import { useSize } from './size-context'

type Props = {
  children: ReactNode
  className?: string
}

export const HeadlineSubtitle = ({ children, className }: Props) => {
  const mainSize = useSize()

  const variant: Record<Size, ComponentProps<typeof Typography>['variant']> = {
    large: 'b1',
    default: 'b2',
  }

  return (
    <Typography
      className={className}
      color="textSecondary"
      css={styles.text}
      data-testid="headline-subtitle"
      variant={variant[mainSize]}
    >
      {children}
    </Typography>
  )
}
