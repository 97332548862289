import { css } from '@emotion/react'

export const root = css`
  position: absolute;
  bottom: 0;
  right: 16px;
  opacity: 0.5;
`

export const showOnHoverOnly = css`
  opacity: 0;

  &:hover {
    opacity: 0.5;
  }
`
